@import "../../style/colors.scss";

.Button {
  display: flex;
  font-size: 14px;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0px 12px;
  width: min-content;
  height: 33px;
  background: $green;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  white-space: nowrap;
  border: 3px #ffffff00 solid;
  &:hover {
    background: $dark-green;
    border: 3px $dark-green solid;
  }
  &:active {
    opacity: 0.85;
    background: $dark-green;
    border: 3px $dark-green solid;
  }
  &.inactive {
    opacity: 0.5;
  }

  &.gray {
    background: $light-grey;
    color: $black;
    &:hover {
      background: $grey;
      border: 3px rgba(255, 255, 255, 0) solid;
    }
    &:active {
      background: $dark-grey;
    }
  }
  &.white {
    background: $white;
    color: black;
  }
  &.greenwhite {
    background: $white;
    color: $green;
    fill: $green;
    &:hover {
      color: $dark-green;
      border: 3px $white solid;
      svg {
        fill: $dark-green;
        stroke: $dark-green;
      }
    }
    &:active {
      color: $dark-green;
      svg {
        fill: $dark-green;
        stroke: $dark-green;
      }
    }
  }
  svg {
    margin: 5px;
    transform: rotate(180deg);
  }
}
