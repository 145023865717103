$body-min-width: 992px;

$extra-small: 345px;
$small: 576px;
$medium: 768px;
$large: 992px;
$extra-large: 1200px;
$extra-extra-large: 1350px;
$extra-extra-extra-large: 1500px;

@if $body-min-width >= $extra-small {
  $extra-small: 0px;
}
@if $body-min-width >= $small {
  $small: 0px;
}
@if $body-min-width >= $medium {
  $medium: 0px;
}
@if $body-min-width >= $large {
  $large: 0px;
}
@if $body-min-width >= $extra-large {
  $extra-large: 0px;
}
@if $body-min-width >= $extra-extra-large {
  $extra-extra-large: 0px;
}
@if $body-min-width >= $extra-extra-extra-large {
  $extra-extra-extra-large: 0px;
}

/* This let the strategy still be here


 [---------a-----------b------c------------]

 min-width a   = X > a

 max-width a   = X < a

 c{
  ...
  max-width c {
     this for [0 - c]
  }
  max-width b {
    // тут дійсні також правила від max-width c ми можемо їх переписати або просто доповнити
    this for [0 - b]
 }
 }

 с{

  min-width b {
       // тут дійсні також правила від max-width c ми можемо їх переписати або просто доповнити

    this for [b - 100]
 }
 min-width a {
   this for [a - 100]

 }
 */
