.SetNewPassword {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8vh;
  header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      font-size: 34px;
      line-height: 40px;
      font-family: "Medium";
      color: #000000;
    }
    span {
      font-weight: 100;
      font-size: 16px;
      line-height: 19px;
      a {
        font-weight: 100;
        font-size: 16px;
        line-height: 19px;
        color: #00df94;
        &:hover {
          color: #00c886;
        }
        &:active {
          color: #00b176;
        }
      }
    }
  }
  .form {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    .headerTitle {
      font-family: "Medium";
    }
  }
  .errorSMS {
    color: red;
    font-size: 14px;
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .Button {
      font-size: 16px;
      line-height: 19px;
      color: black;
      background-color: white;
      border: 1px solid black;
    }
    .back {
      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }

      color: #00df94;
    }
  }
}
