.ActiveLot {
  display: flex;
  flex-direction: column;
  cursor: pointer;

  .title {
    text-align: center;
    font-family: 'Bold';
    font-size: 24px;
  }

  .context {
    display: flex;
    flex-direction: row;
    gap: 20px;

    .ActiveLotGalary {
      .p-galleria {
        width: 100px;
      }

      .p-galleria-item {
        height: 100px;
      }
    }

    .div_active_lot {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 20px;
      width: 100%;

      .title_active_lot {
        letter-spacing: 1px;
        font-size: 22px;
        text-align: center;
        font-weight: 600;
        font-family: 'Light';
        padding-bottom: 10px;
      }

      .CountdownTimer {
        .seconds {
          font-size: 30px !important;
        }
      }

      .CurrentBid {
        .title {
          letter-spacing: 0.6px;
          font-weight: 600;
          font-family: 'Light';
          font-size: 20px;
        }
      }
    }
  }
}