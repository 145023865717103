.PasswordRecovery {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8vh;
  header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 40px;
      color: #000000;
    }
    span {
      font-weight: 100;
      font-size: 16px;
      line-height: 19px;
      display: block;
      width: 80%;
    }
  }
  .form {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    .headerTitle {
      font-family: "Medium";
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .Button {
      font-size: 16px;
      line-height: 19px;
      color: black;
      background-color: white;
      border: 1px solid black;
    }
    .back {
      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }
      color: #00df94;
    }
  }
}
