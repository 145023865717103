.SelectedFilterOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 15px 0;
  gap: 14px;
  flex-wrap: wrap;
  .option {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 14px;

    height: 33px;
    width: max-content;

    border: 1px solid #cccccc;
    border-radius: 5px;
    .Dagger {
      cursor: pointer;
      margin: 5px 0px 5px 9px;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .WasteBasket {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    gap: 14px;
    width: 46px;
    height: 33px;
    border: 1px solid #00df94;
    border-radius: 6px;
    svg {
      transform: scale(1.3);
    }
  }
}
