.p-dialog .Table .type_table_vertical {
  .p-datatable-wrapper {
    max-height: 100% !important;
    .p-datatable-table {
      display: flex;
      flex-direction: row;
      thead > tr,
      tbody > tr {
        display: flex;
        flex-direction: column;
        td,
        th {
          height: 60px;
          width: 300px;
          display: flex !important;
          align-items: center !important;
          justify-content: center !important;
        }
      }
    }
  }
}

.Table {
  width: 100%;
  .p-datatable {
    * {
      transition: all ease 0.8s;
    }
    width: 100%;
    .p-datatable-wrapper {
      height: 100%;
      max-height: calc(100vh - 200px);
    }
  }

  .p-multiselect-label-container {
    height: fit-content;
    .p-multiselect-label {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
    }
  }
  .p-column-title {
    width: auto;
    hyphens: auto;
    white-space: pre-wrap;
    word-wrap: break-word !important;
  }
  td {
    white-space: wrap !important;
  }
  .Button {
    padding: 25px 12px;
  }
  .search_bloc {
    i:hover {
      transition: all 0.2s;
      transform: scale(1.1);
    }
  }
}
.p-multiselect-items-wrapper {
  max-height: 700px !important;
}
.p-dialog {
  padding: 50px;
  box-shadow: none !important;
  .p-datatable-wrapper {
    max-width: 100%;
    height: 100%;
    max-height: calc(100vh - 400px);
  }
}
.p-dropdown-panel {
  z-index: 100000 !important;
}
.linkDownloadInvoice-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
}
.linkDownloadInvoice {
  background-color: white;
  height: 20px;
  color: #00bd7e !important;
  padding: 0 !important;
  text-decoration: underline;
}
.absolute {
  position: absolute !important;
  top: -10px;
}
.DialogDownloadInvoice {
  display: flex;
  flex-direction: column;
  gap: 15px;
  .btns {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 25px;
    input {
      font-family: "Regular";
    }
    .under_input_title {
      font-style: italic;
    }
  }
  .error {
    color: rgb(230, 0, 0);
  }
  .Button {
    &.inactive {
      opacity: 0.5;
      pointer-events: none;
      &:hover {
        background: #00df94;
      }
    }
  }
}
.ProductsDialog_form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 5px;
  > div {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    > * {
      width: 100%;
    }
  }
  > * {
    height: 40px;
  }
}
