.Registration {
  margin-top: 60px;
  margin-bottom: 150px;

  display: flex;
  flex-direction: row;
  gap: 6vh;

  .navBar {
    display: flex;
    flex-direction: column;
    gap: 63px;
    .header {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      .mainTitle {
        font-size: 34px;
        line-height: 40px;
        font-family: "Medium";
        color: #000000;
      }
      span {
        a {
          font-size: 16px;
          line-height: 19px;
          color: #00df94;
          &:hover {
            color: #00c886;
          }
          &:active {
            color: #00b176;
          }
        }
      }
    }
    .step{
      .title{
        width: max-content;
      }
    }
    .progresBar {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 60px;
      .step {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 20px;
        color: #cccccc;
        position: relative;
        &.passed {
          color: #000000;
          .circle {
            border: 1px solid #00df94;
            background-color: #00df94;
            span {
              color: #ffffff;
            }
          }
          .title {
          }
          .line {
            border: 1px solid #00df94;
          }
        }
        &.current {
          color: #000000;
          .circle {
            border: 1px solid #00df94;
            background-color: #ffffff;
            span {
              color: #000000;
            }
          }
          .title {
            font-family: "Medium";
          }
          .line {
            border: 1px solid #00df94;
          }
        }
        .circle {
          width: 30px;
          height: 30px;
          aspect-ratio: 1/1;
          border-radius: 50%;
          background-color: #ffffff;
          border: 1px solid #cccccc;

          display: flex;
          align-items: center;
          justify-content: center;
          span {
            font-size: 12px;
            line-height: 14px;
          }
        }
        .line {
          z-index: -1;
          position: absolute;
          bottom: 25px;
          left: 15px;

          width: 0px;
          height: 70px;
          border: 1px solid #cccccc;
        }
      }
    }
  }

  .containerRegistration {
    width: 100%;
  }
}
