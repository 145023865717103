@font-face {
  font-family: "Black";
  src:
    local("Gilroy-Black"),
    url("./Gilroy-Black.ttf") format("truetype");
}

@font-face {
  font-family: "Bold";
  src:
    local("Gilroy-Bold"),
    url("./Gilroy-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Extrabold";
  src:
    local("Gilroy-Extrabold"),
    url("./Gilroy-Extrabold.ttf") format("truetype");
}

@font-face {
  font-family: "Heavy";
  src:
    local("Gilroy-Heavy"),
    url("./Gilroy-Heavy.ttf") format("truetype");
}

@font-face {
  font-family: "Light";
  src:
    local("Gilroy-Light"),
    url("./Gilroy-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Medium";
  src:
    local("Gilroy-Medium"),
    url("./Gilroy-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Regular";
  src:
    local("Gilroy-Regular"),
    url("./Gilroy-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Semibold";
  src:
    local("Gilroy-Semibold"),
    url("./Gilroy-Semibold.ttf") format("truetype");
}

@font-face {
  font-family: "Thin";
  src:
    local("Gilroy-Thin"),
    url("./Gilroy-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "UltraLight";
  src:
    local("Gilroy-UltraLight"),
    url("./Gilroy-UltraLight.ttf") format("truetype");
}
