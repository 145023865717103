@import "/src/style/breakpoints";
@import "/src/style/colors";

.Galleria {
  width: 100%;

  .GalleriaPrime {
    * {
      :focus-visible {
        outline: none !important;
      }
    }
    .p-galleria-thumbnail-item-content:focus {
      box-shadow: none !important;
    }
    .p-galleria-thumbnail-wrapper {
      border-radius: 5px;
      overflow: hidden;
      .p-galleria-thumbnail-items-container {
        transform: scale(1.015);
        .p-galleria-thumbnail-items {
          .p-galleria-thumbnail-item {
            padding: 0 5px;
            .p-galleria-thumbnail-item-content {
              display: flex;
              align-items: center;
              justify-content: center;
              overflow: hidden !important;
              border-radius: 5px;
              img {
                height: 90px;
              }
            }
          }
        }
      }
    }
    .p-galleria-content {
      img {
        border-radius: 5px;
      }
      .p-galleria-thumbnail-container {
        padding: 10px 0;
      }
    }
    .p-galleria-thumbnail-container {
      background-color: rgba(255, 255, 255, 0) !important;
      .p-link {
        display: none;
        svg {
          color: black !important;
        }
      }
    }
    .p-galleria-item-container {
      align-items: center;
      justify-content: center;
    }
    .p-galleria-item {
      height: 500px;
      width: auto;
      img {
        height: 100%;
        width: auto;
      }
    }
    .p-galleria-item-wrapper {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
    }
  }
  .p-galleria-item-nav {
    z-index: 100;
    &:focus {
      box-shadow: none !important;
    }
    &:hover {
      background: rgba(255, 255, 255, 0.268) !important;
    }
    svg {
      path {
        fill: $green;
      }
    }
  }
}
.PhotoView-Slider__Backdrop {
  background: rgba(0, 0, 0, 0.927) !important;
}
.PhotoView-Slider__BannerRight {
  gap: 20px;
  > svg {
    transform: scale(1.3);
  }
}
.PhotoSlider_toolbar {
  display: flex;
  flex-direction: row;
  gap: 30px;
  > * {
    transform: scale(1.3);
    opacity: 0.7;
    &:hover {
      transform: scale(1.35);
      opacity: 1;
    }
  }
}

.PhotoView-Portal {
  .PhotoView-Slider__Counter {
    font-size: 20px !important;
  }
}

.PhotoView__PhotoWrap {
  img {
    border-radius: 4px;
  }
}
