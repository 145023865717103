.Debug {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .threads {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    justify-content: center;
  }
  .Button {
    opacity: 0.7;
  }
  .CheckBox_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    margin: 3px;
  }
  .checkLotBloc {
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .controlPanel {
    display: flex;
    flex-direction: row;
    gap: 50px;
    .LogFilter,
    .BackLight {
      display: flex;
      flex-direction: column;
      gap: 30px;
      width: 300px;
      .enter {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
      }
      .list {
        .item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
  }
}
