.ResultLot {
  width: 100%;
  > div {
    border-radius: 6px;
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 14px;
  }
  h4 {
    text-align: center;
    font-size: 14px;
    font-family: "Light";
  }
  .win {
    background: #e5fcf4;
    gap: 14px;
    align-self: stretch;

    .Button {
      background-color: #00000000;
      color: #000;
      font-family: "Medium";
      border: 1px solid #000;
    }
  }
  .win_reservation {
    background: #f4f4f4;
  }
  h4 {
    text-align: center;
  }
  h4 {
    font-family: "Thin";
  }
  h5 {
    text-align: center;
    color: #000000;
    font-size: 12px;
    font-family: "Thin";
  }
  .lost {
    background: #ffe6e6;
    .Button {
      background-color: #00000000;
      color: #000;
      font-family: "Medium";
      border: 1px solid #000;
    }
  }
  a:not(.Button) {
    color: #00df94;
    border-bottom: 0.5px solid #00df94;
  }
  .Button {
    margin-top: 10px;
  }
}
