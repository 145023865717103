@import "/src/style/breakpoints";
@import "/src/style/colors";

.CardLotProcessing {
  .ShadowContainer {
    padding: 25px;
    padding-bottom: 0;
  }
  .CardLotProcessing_container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex: 1 2 100px;
    min-height: 220px;
    &:hover {
      animation: hoveLot 0.5s linear forwards;
    }
    @keyframes hoveLot {
      0% {
        background: #ffffff00;
      }
      100% {
        background: #f3f3f456;
      }
    }
    .description {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 15px;
      padding-bottom: 25px;
      width: 50%;
      @media screen and (max-width: $extra-large) {
        width: 100%;
      }
      .main {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .header {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          .name {
            font-family: "Bold";
            font-size: 20px;
            line-height: 23px;
            color: #000000;
          }
        }
      }
    }

    .prices {
      width: 100%;
      display: flex;
      flex-direction: row;

      align-items: flex-end;
      justify-content: space-between;
      flex: 25%;
      .pay {
        .Button {
          position: relative;
          height: 36px;
          font-size: 18px;
          font-family: "Medium";
          width: 110px;
          float: right;
        }
      }
      .index {
        font-size: 18px;
        font-family: "SemiBold";
      }
      .number {
        font-size: 1.1em;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 3px;
        white-space: nowrap;
        .vat {
          display: flex;
          align-items: center;
          img {
            width: 25px;
          }
        }
      }
      span {
        font-family: "Regular";
        margin: 0 5px 0 1px;
      }
      .totalPrice{
        display: flex;
        flex-direction: row;
        gap: 10px;
        flex-wrap: wrap;
      }
      .price {
        font-size: 18px;
        font-family: "Medium";
        white-space: nowrap;
       
      }
    }
  }
  .ProgressBar_container {
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $extra-large) {
      margin-top: 10px;
      padding-bottom: 25px;
    }
    height: auto;
    .ProgressBar {
      margin-bottom: 0px;
      &.second {
        margin-inline: auto;
        @media screen and (min-width: $extra-large) {
        }
      }
    }
  }
  .ProgressBar {
    margin-bottom: 25px;
    justify-content: center;
    &.main {
      @media screen and (max-width: $extra-large) {
        display: none !important;
      }
    }
    &.second {
      margin-inline: auto;
      @media screen and (min-width: $extra-large) {
        display: none !important;
      }
    }
  }
}
