.StartPrice {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  .title_tax {
    display: flex;
    flex-direction: column;

    .title {
      font-size: 22px;
      font-family: "Bold";
    }
  }
  .price {
    font-size: 34px;
    font-family: "Bold";
  }
  .value {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-end;
  }
  .vat {
    position: absolute;
    top: -10px;
    right: -30px;
    img {
      transform: scale(1.2);
    }
  }
}
