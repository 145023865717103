.CountdownTimer {
  display: flex;
  flex-direction: row;
  gap: 3px;
  flex-wrap: nowrap;
  .number {
    display: inline-block;
    width: 20px;
    text-align: end;
  }
}
