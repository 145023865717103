.Page500 {

  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  .title500 {
    font-family: "Extrabold";
    font-size: 193.714px;
    line-height: 227px;

    color: #00df94;
  }
  .title {
    font-family: "Extrabold";
    font-size: 34px;
    line-height: 40px;
    color: #00df94;
  }
  .description {
    font-size: 16px;
    line-height: 19px;
  }
}
