@import "/src/style/breakpoints";
@import "/src/style/colors";

.Step_3 {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .titles {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .main_title {
      width: fit-content;
      display: flex;
      flex-direction: column;

      font-size: 34px;
      line-height: 40px;
      font-weight: 500;
    }
    .sub_title {
      width: auto;
      font-size: 16px;
      line-height: 19px;
      font-weight: 100;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .typeAccount {
      display: flex;
      flex-direction: row;
      gap: 25px;
      section {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        .text {
          .head {
            font-family: "Medium";
            font-size: 16px;
            line-height: 19px;

            color: #000000;
          }
          .bottom {
            font-family: "Light";
            font-size: 14px;
            line-height: 16px;
            color: #999999;
          }
        }
      }
    }
    .data_entry {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 20px;
      .Input {
        width: calc(40% - 20px);
        @media screen and (max-width: $extra-large) {
          width: 70%;
        }
        .under_input_title {
          opacity: 0;
          color: red;
        }
        .headerTitle {
          white-space: nowrap;
        }
      }
    }
  }

  .data_entry {
    width: 100%;
    height: 50%;
  }
  .errorSMS {
    color: red;
    font-size: 14px;
  }
  .privacyPolicy {
    display: flex;
    align-items: center;

    font-family: "Light";
    font-size: 14px;
    line-height: 16px;
    .MuiButtonBase-root {
      border-radius: 4px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }

    span {
      position: relative;
      top: 2px;
      a {
        border-bottom: 0.1px solid rgba(0, 0, 0, 0.556);
      }
    }
  }
  .Button {
    &.inactive {
      opacity: 0.5;
      pointer-events: none;
      &:hover {
        background: #00df94;
      }
    }
  }
  .privacyPolicy {
    display: flex;
    gap: 10px;
    align-items: center;
    font-family: "Light";
    font-size: 14px;
    line-height: 16px;
    .MuiButtonBase-root {
      border-radius: 4px;
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
    span {
      a {
        border-bottom: 0.1px solid rgba(0, 0, 0, 0.556);
      }
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    gap: 10px;
    .greenwhite {
      font-weight: 800;
    }
  }
  .MuiInputBase-root {
    width: 100%;
    color: #777;
  }
}
