.Settings {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .ShadowContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;

    .title {
      font-size: 34px;
      line-height: 40px;
    }
    .list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 18px;
      .item {
        min-width: 200px;
        max-width: 350px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 18px;

        .CheckBox {
          display: flex !important;
          border-radius: 4px;
        }
        div {
          display: flex;
          flex-direction: column;
          gap: 5px;
          .header {
            font-family: "Medium";
            font-size: 16px;
            line-height: 19px;
          }
          .text {
            font-family: "Regular";
            font-size: 12px;
            line-height: 14px;
          }
        }
      }
    }
  }
}
