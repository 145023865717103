.ConfirmBit{
  width: 100%;
  .confirmation {
    display: flex;
    padding: 32px;
    flex-direction: column;
    align-items: center;
    gap: 14px;
    border-radius: 6px;
    background: #f4f4f4;

    .title {
      color: #000;
      text-align: center;
      font-size: 16px;
      font-family: "Regular";
      .price {
        font-family: "SemiBold";
      }
    }
    .buttons {
      display: flex;
      flex-direction: row;
      gap: 20px;
      .Button {
        display: flex;
        width: 64px;
        padding: 8px 14px;
        justify-content: center;
        align-items: center;
        gap: 14px;
        border-radius: 4px;
        border: 1px solid #ccc;
        background-color: #f4f4f4;
        color: #000;
        &:hover {
          border: 1px solid #393939;
        }
      }
    }
  }

}
