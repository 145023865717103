.AdminContainer {
  min-height: 70vh;
  .inner_container {
    display: flex;
    flex-direction: row;
    gap: 30px;
    width: 100%;
    padding: 0 5vw;


    .navbar {
      width: 220px;
      min-width: 220px;
      max-width: 220px;
      display: flex;
      flex-direction: column;

      width: fit-content;

      li {
        padding: 10px 0;
        width: 100%;
        white-space: nowrap;
        padding: 14px;
        border-left: 1px solid #cccccc;

        a {
          white-space: nowrap;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          font-style: normal;
          color: #000;
          display: block;
          width: 100%;
        }
        &.active {
          background: #e5fcf4;
          border-left: 2px solid #00df94;

          a {
            font-family: "SemiBold";
            color: #00df94;
          }
        }
        &:hover {
          border-left: 2px solid #00df94;
        }
      }
    }
    .bloc-info {
      width: inherit;
      overflow: auto;
      padding:  15px;
    }
  }
}
