.DropDownList {
  &.invalid {
    .p-dropdown {
      border-bottom: solid 1px #ff0000 !important;
    }
  }
  .headerTitle {
    font-family: "Medium";
    font-size: 16px;
    color: #000000;
  }
  .p-dropdown {
    border: 0;
    border-radius: 0;
    border-bottom: solid 1px #999999;
  }
  .p-dropdown:not(.p-disabled).p-focus {
    box-shadow: none;
    border: 0;
    border-bottom: solid 1px #999999;
  }
  .p-dropdown:not(.p-disabled):hover {
    border-color: initial;
  }
  .p-inputtext {
    font-size: 14px;
    line-height: 16px;
    color: #777;
    padding: 7px 0;
  }
}