@import "/src/style/breakpoints";
@import "/src/style/colors";

.NavBarContainer {
  min-height: 70vh;
  .container {
    display: flex;
    flex-direction: row;
    max-width: 100%;
    gap: 60px;
    @media screen and (max-width: $extra-large) {
      gap: 10px;
    }

    .navbar {
      display: flex;
      flex-direction: column;
      width: fit-content;
      $padding: 14px;
      li {
        padding: 10px 0;
        width: 100%;
        white-space: nowrap;
        padding: $padding;
        border-left: 1px solid #cccccc;

        a {
          white-space: nowrap;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          font-style: normal;
          color: #000;
          display: block;
          width: 100%;
        }
        &.active {
          padding-left: calc($padding - 1px);
          background: #e5fcf4;
          border-left: 2px solid #00df94;

          a {
            font-family: "SemiBold";
            color: #00df94;
          }
        }
        &:hover {
          padding-left: calc($padding - 1px);
          border-left: 2px solid #00df94;
        }
      }
    }
    .bloc-info {
      width: 100%;
    }
  }
}
