@import "/src/style/breakpoints";
@import "/src/style/colors";

.LineTechnicalWorks {
  .line {
    padding: 0 10px;
    align-items: baseline;
    background: $light-green;
    color: rgb(0, 0, 0);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    left: 0px;
    position: fixed;
    min-width: $body-min-width;
    width: 100%;
    height: 25px;
    z-index: 999;
    top: 0px;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    word-spacing: 2px;
    font-family: 'Medium';
  }
}
