@import "/src/style/breakpoints";
@import "/src/style/colors";

.LanguageChange {
  display: inline-block;
  height: fit-content;
  position: relative;
  cursor: pointer;
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    a {
      display: block;
    }
    svg {
      fill: white;
      transition: all 0.12s ease-out;
      transform: scale(1.2) rotate(0deg);
      &[open] {
        transform: scale(1.2) rotate(-180deg);
      }
    }
  }

  .dropDownList {
    position: absolute;
    right: 0px;
    top: 30px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 12px;

    background: #ffffff;

    box-shadow: 0px 0px 10px -1px rgba(144, 144, 144, 0.405);
    @media screen and (max-width: $large) {
      box-shadow: none;
    }

    /* Inside auto layout */
    animation-name: show;
    animation-duration: 0.3s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;

    //close
    display: none;

    &.open {
      display: flex;
    }

    .option {
      font-size: 16px;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 0px;
      gap: 12px;
      align-items: center;
      border-bottom: 0.2px solid rgba(0, 0, 0, 0);
      &:hover {
        border-bottom: 0.2px solid rgba(0, 0, 0, 0.649);
        animation-name: hover;
        animation-duration: 0.4s;
        animation-timing-function: ease;
        animation-fill-mode: forwards;
        @media screen and (max-width: $large) {
          border-bottom: 0.2px solid rgba(255, 255, 255, 0.649);
        }
      }

      &.activeLanguage {
        color: #00df94;
      }

      &:hover {
        font-weight: 500;
      }
    }
  }
  @media screen and (max-width: $large) {
    .dropDownList {
      position: static;

      background: #000000;
      color: white;

      /* Notifications */
    }
  }
}

@keyframes show {
  from {
    opacity: 0.3;
  }
  to {
    opacity: 1;
  }
}
@keyframes hover {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.flash {
  animation-name: flash;
  animation-duration: 0.3s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
}

@keyframes flash {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.8;
  }
  to {
    opacity: 1;
  }
}
