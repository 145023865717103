.AddToFavoriteBtn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 12px;
  cursor: pointer;

  width: 39px;
  height: 27px;
  background: #f4f4f4;
  border-radius: 6px;

  &.active {
    background-color: #00df94;
  }
  .pi-bookmark-fill {
    color: #ffffff;
  }
}
