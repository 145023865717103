@import "/src/style/breakpoints";
@import "/src/style/colors";

.Filters {
  margin-top: 50px;
  .DropDown {
    .Input {
      .icon {
        @media screen and (max-width: $large) {
          filter: invert(100%);
        }
      }
      input {
        @media screen and (max-width: $large) {
          background: none;
          width: 100%;
        }
      }
      .under_input_title {
        @media screen and (max-width: $large) {
          color: #ffffff;
        }
      }
    }
    .header {
      @media screen and (max-width: $large) {
        color: #ffffff;
      }
      @media screen and (max-width: $extra-large) {
        font-size: 18px;
        margin-bottom: 10px;
      }
    }
  }
  .containerDD {
    display: flex;
    gap: 41px;
    @media screen and (max-width: $large) {
      flex-direction: column;
    }
    @media screen and (max-width: $extra-extra-large) {
      gap: 20px;
    }
    @media screen and (max-width: $extra-large) {
      width: 90%;
    }
    @media screen and (max-width: $large) {
      width: 100%;
      flex-direction: column;
    }

    > * {
      width: 100%;
    }
    > div:not(.DropDown) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 27px;
    }
    .third-filter {
      @media screen and (max-width: $large) {
        display: none;
      }
      gap: 15px;
      .content {
        section {
          @media screen and (max-width: $extra-extra-large) {
            gap: 5px;
          }
          article {
            justify-content: space-evenly;
          }
        }
      }
    }
    &:first-child {
      margin-top: 0px;
    }
    &:last-child {
      margin-bottom: 0px;
    }

    .scroll_container {
      overflow-y: scroll;
      height: 140px;
      margin-top: 10px;
      width: 100%;
      display: flex;
      flex-direction: column;

      padding-left: 5px;
      gap: 16px;
      padding-bottom: 10px;

      &::-webkit-scrollbar {
        width: 2px;
      }

      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        @media screen and (max-width: $large) {
          box-shadow: inset 0 0 6px rgba(255, 254, 254, 0.3);
          -webkit-box-shadow: inset 0 0 6px rgba(150, 146, 146, 1);
        }
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
        @media screen and (max-width: $large) {
          box-shadow: inset 0 0 6px rgba(255, 254, 254, 1);
          -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 1);
        }
      }

      label {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 14px;
        cursor: pointer;
        color: #000000b4;
        @media screen and (max-width: $large) {
          color: #ffffff;
        }
        input {
          width: 20px;
          height: 20px;
          min-width: 20px;
          min-height: 20px;
          border-bottom: solid 2px #999999;
          * {
            border-radius: 10px !important;
          }
        }
        span {
          font-size: 14px;
          line-height: 16px;
          font-weight: 400;
        }
        b {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .homePage_more {
    margin-top: 75px;
    > a {
      color: #00df94;
      font-family: "Medium";
      font-size: 16px;
      line-height: 19px;
      > svg {
        margin-left: 14px;
        font-weight: 400;
      }
    }
    @media screen and (max-width: $large) {
      margin-top: 65px;
      text-align: center;
    }
  }
}
