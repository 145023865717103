@import "/src/style/breakpoints";
@import "/src/style/colors";

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .tag {
    word-break: break-all;
    font-size: 14px;
    font-family: "Regular";
    padding: 6px 12px;

    display: flex;
    gap: 3px;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: nowrap ;

    width: max-content;
    background: #f4f4f4;
    border-radius: 4px;
    @media screen and (max-width: $extra-large) {
      max-width: 300px;
    }
  }
}
