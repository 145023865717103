.InfoAboutCheckout {
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  justify-content: center;
  .main {
    display: flex;
    flex-direction: row;
    gap: 150px;

    .product,
    .paymentMethods {
      width: 250px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Medium;
      }
    }
  }
  .explanatoryInformation {
    display: flex;
    flex-direction: column;
    gap: 10px;
    ul {
      margin-top: 10px;
      margin-left: 25px;
      list-style: disc;
    }
  }
  .price {
    font-size: 20px;
    font-family: Semibold;
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .tariffInfo {
    width: 250px;
    height: 150px;
    box-shadow: 0px 1px 20px 4px rgba(0, 0, 0, 0.15);
    background-color: rgba(216, 216, 216, 0.127);
  }
  .btNext {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;
    .price{
      height: 20px;
      align-items: center;
      justify-content: center;
    }
  }
}
