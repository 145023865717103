@import "/src/style/breakpoints";

@media screen and (max-width: $extra-large) {
  .p-dialog {
    * {
      font-size: 25px;
    }
  }
  .ProductsDialog_form{
    gap: 30px;
    padding: 25px;
  }
}
.AgentContainer {
  min-height: 70vh;
  .inner_container {
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 100%;
    padding: 0 5vw;
    @media screen and (max-width: $extra-large) {
      .Table {
        * {
          font-size: 25px;
        }
      }
    }
    .navbar {
      @media screen and (max-width: $extra-large) {
        transform: scale(2.2);
      }
      width: 100%;

      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      li {
        padding: 10px 0;
        width: fit-content;

        white-space: nowrap;
        padding: 14px;
        border-bottom: 1px solid #cccccc;

        a {
          white-space: nowrap;
          width: 100%;
          font-size: 16px;
          line-height: 19px;
          font-style: normal;
          color: #000;
          display: block;
          width: 100%;
        }
        &.active {
          background: #e5fcf4;
          border-bottom: 2px solid #00df94;

          a {
            font-family: "SemiBold";
            color: #00df94;
          }
        }
        &:hover {
          border-bottom: 2px solid #00df94;
        }
      }
    }
    .bloc-info {
      width: inherit;
      overflow: auto;
      padding: 15px;
      @media screen and (max-width: $extra-large) {
        padding-top: 50px !important;
      }
    }
  }
}
