.CurrentBid {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 20px;
    font-family: "Bold";
  }
  .price {
    font-size: 34px;
    font-family: "Regular";
    &.green {
      color: #00df94;
    }
    &.red {
      color: red;
    }
  }
  position: relative;
  .vat {
    position: absolute;
    top: -10px;
    right: -30px;
    img {
      transform: scale(1.2);
    }
  }
}
