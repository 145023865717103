@import "/src/style/breakpoints";
@import "/src/style/colors";
$width: 48%;
.Payments {
  .paymentsData {
    margin: 25px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    gap: 25px;
  }
  .PaymentItem {
    display: flex;
    flex-direction: row;
    gap: 30px;
    padding: 25px;
    padding-bottom: 0;
    min-height: 250px;
    .tariffInfo_container {
      padding-bottom: 25px;
      height: 100%;
    }
    .tariffInfo {
      padding: 20px;
      width: 250px;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      justify-content: center;
      align-items: center;
      p {
        white-space: nowrap;
      }
    }
    .info {
      padding-bottom: 25px;
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;
      gap: 10px;
      .header {
        font-family: "Bold";
        font-size: 20px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .otherInfo {
        height: 100%;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $extra-extra-large) {
          flex-direction: row;
          gap: 20px;
          flex-wrap: wrap;
        }
        > div {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          gap: 5px;
          width: fit-content;
          @media screen and (max-width: $extra-extra-large) {
            width: 100% !important;
          }
          &.first {
            display: flex;
            flex-direction: column;
            width: 20%;
            @media screen and (min-width: $extra-large) and (max-width: $extra-extra-large) {
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              > div {
                width: $width;
              }
              .logo_payment_provider {
                justify-content: flex-end;
                div {
                  display: flex;
                  justify-content: flex-end;
                }
              }
            }

            .status {
              white-space: nowrap;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              gap: 10px;
            }
          }
        }
      }
      .payment_provider {
        display: flex;
        flex-direction: column;
        @media screen and (max-width: $extra-extra-large) {
          gap: 10px;
          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            gap: 10px;
          }
        }
        .WireTransfer {
          height: 40px;
          width: 60px;
        }
        .imoje, .pp_paynow {
          max-width: 170px;
          img {
            width: 60%;
          }
        }
      }
      .prices {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        * {
          white-space: nowrap;
        }
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          gap: 10px;
          @media screen and (max-width: $extra-extra-large) {
            justify-content: space-between;
          }
        }

        @media screen and (min-width: $extra-large) and (max-width: $extra-extra-large) {
          flex-direction: row !important;
          justify-content: space-between !important;
          flex-wrap: wrap !important;
          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: $width;
          }
        }
        @media screen and (max-width: $extra-large) {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          width: 100%;
          > div {
            width: 100%;
            display: flex;
            flex-direction: row;
          }
        }
      }
      .dates {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        gap: 5px;
        * {
          white-space: nowrap;
        }
        @media screen and (min-width: $extra-large) and (max-width: $extra-extra-large) {
          flex-direction: row !important;
          justify-content: space-between !important;

          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: $width;
          }
        }
        @media screen and (max-width: $extra-large) {
          > div {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
          }
        }
      }
    }
  }
  .p-tag {
    font-family: Medium;
  }
  .p-tag-success{
    background-color: $green !important;
  }
}
