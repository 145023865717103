.Features {
  width: 100%;
  .Features_container {
    .ExportedFeatures,.OwnFeatures {
      box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.15);
      overflow: hidden;
      border-radius: 5px;
      > * {
        border-radius: 5px;
     
      }
    }
  }
}
