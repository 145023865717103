@import "/src/style/breakpoints";
@import "/src/style/colors";

.PersonalInformation {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .previewData {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    div {
      .name{
        font-family: "Medium";
        font-size: 24px;
        line-height: 40px;
      }
      div {
        font-family: "Medium";
      }
    }
    .Button {
      float: right;
      font-size: 16px;
      line-height: 19px;
      color: black;
      background-color: white;
      border-radius: 0;
      border: none;
      align-items: start;
    }
  }
  .personalDate,
  .addressInfo {
    display: flex;
    flex-direction: column;
    gap: 36px;
    .typeAccountbloc {
      display: flex;

      flex-direction: column;
      gap: 24px;
      .typeTitle {
        font-family: "Medium";
        font-size: 20px;
        line-height: 23px;
      }
    }
  }
  .inputs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 35px;
    width: 100%;
    > * {
      width: calc(33% - 35px);
      @media screen and (max-width: $extra-large) {
        width: calc(50% - 20px);
      }
      width: 30%;
    }
    .under_input_title {
      opacity: 0;
    }
  }
}
