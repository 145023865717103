@import "../../style/colors.scss";

.SelectButton {
  width: 100%;
  display: flex;
  flex-direction: row !important;
  align-items: center;
  justify-content: center;

  * {
    white-space: nowrap;
  }
  .Button {
    font-size: 16px;
    font-family: "Semibold";
    &.white {
      color: #000000df;
    }
    border-radius: 0px;
    border: 1px solid $green;
    border-right: 0px;
  }
  $radius: 4px;
  .Button:first-of-type {
    border-top-left-radius: $radius;
    border-bottom-left-radius: $radius;
  }
  .Button:last-of-type {
    border-right: 1px solid $green;
    border-top-right-radius: $radius;
    border-bottom-right-radius: $radius;
  }
}
