.Interval {
  display: flex;
  flex-direction: column;
  gap: 15px;
  > div {
    align-items: center;
    justify-content: space-evenly;
    display: flex;
    flex-direction: row;
    gap: 10px;
    div {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
  > section {
    display: flex;
    flex-direction: column;
    gap: 8px;
    article {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
      padding-right: 10px;
      .text {
        width: 40px;
        white-space: nowrap;
      }
      .Select {
        width: 100%;
      }
    }
  }
  .MuiInputBase-root {
    * {
      font-family: "Medium" !important;
    }
  }
}
