.SynchronizeFeatures{
  .ShadowContainer{
    padding: 10px;
    >span{
      font-size: 19px;
      display: block;
      padding: 10px;
    }
    .p-datatable-wrapper {
      max-height: 500px;
    }
  }
}
