@import "/src/style/breakpoints";
@import "/src/style/colors";

.TopHomeContainer {
  display: flex;
  .TopHomeContainer_container {
    width: 100%;
    height: 100vh;
    z-index: 4;
    .left,
    .right {
      width: 50%;
    }

    display: flex;
    flex-direction: row;
    position: relative;
    top: 0;
    .left {
      padding-left: max(calc(50% - min(45%, 45rem)), calc(calc(100% - 1325px) / 2));
      padding-top: 100px;
      padding-bottom: 100px;
    }
    .right {
      display: flex;
      align-items: flex-end;
    }
    @media screen and (max-width: $extra-extra-large) {
      .left {
        width: 45%;
      }
      .right {
        width: 55%;
      }
    }
  }
  .photo {
    position: absolute;
    z-index: -1;
    top: 0;
    height: 100vh;
    width: inherit;
    z-index: 0;
    background: url("../../assets/images/Rectangle_23.png") no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column-reverse;

    @media screen and (max-width: $large) {
      top: -85px;
      &::after {
        content: "";

        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, 0.448);
        backdrop-filter: blur(8px);
      }
    }
  }
  .bloc {
    width: 80%;
    background: rgba(153, 153, 153, 0.2);
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    align-items: center;
    height: 90px;
    margin-bottom: 20vh;
    padding: 0px 10px;
    backdrop-filter: blur(8px);
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $large) {
      opacity: 0;
    }
    .text {
      color: white;
      font-weight: 400;
      line-height: 19px;
    }
    .button {
      white-space: nowrap;
      margin-left: 20%;
      @media screen and (max-width: $extra-extra-large) {
        margin-left: 0;
      }

      > a {
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        color: white;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0 14px;
        border: solid 2px white;
        padding: 10px 17px;
        border-radius: 5px;
      }
    }
  }
}
