.ProgressBar {
  display: flex;

  .step {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
    color: #cccccc;
    position: relative;
    &.passed {
      color: #000000;
      .circle {
        border: 1px solid #00df94;
        background-color: #00df94;
        span {
          color: #ffffff;
        }
      }
      .title {
      }
      .line {
        border: 1px solid #00df94;
      }
    }
    &.current {
      color: #000000;
      .circle {
        border: 1px solid #00df94;
        background-color: #ffffff;
        span {
          color: #000000;
        }
      }
      .title {
        font-family: "Medium";
      }
      .line {
        border: 1px solid #00df94;
      }
    }
    .circle {
      z-index: 1;
      width: 30px;
      height: 30px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      background-color: #ffffff;
      border: 1px solid #cccccc;

      display: flex;
      align-items: center;
      justify-content: center;
      span {
        font-size: 12px;
        line-height: 14px;
      }
    }
  }
  .line {
    z-index: 0;
    position: absolute;
    top: -15px;
    left: 15px;
    width: 0px;
    border: 1px solid #cccccc;
  }

  &.column {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
    .step {
      text-align: center;
      flex-direction: column;
    }
  }
}
