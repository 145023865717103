.Documents {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .ShadowContainer {
    display: flex;
    flex-direction: column;
    gap: 36px;

    .title {
      font-size: 34px;
      line-height: 40px;
    }
  }
}
