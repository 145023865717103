.Auction_ProgressBar {
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    fill: #00df94;
    * {
      stop-color: #00df94;
    }
  }

  &.green {
    color: #00df94;
  }
  &.red {
    svg {
      fill: red;
      * {
        stop-color: red;
      }
    }
  }

  .Auction_ProgressBar_main {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    height: 30px;
    svg {
      width: 20px !important;
      height: 20px;
    }
    p {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 3px;
      span {
        text-align: end;
        width: min-content;
        &.seconds {
          font-size: 35px;
          width: 40px;
          text-align: center;
        }
        &.number {
          width: 20px;
        }
      }
    }
    .big {
      transform: scale(1.7);
    }
  }
  .ProgressBarLine {
    position: relative;
    top: -6px;
    svg {
      width: 100%;
    }
  }
}
