.TypeAccount {
  display: flex;
  flex-direction: row;
  gap: 25px;
  section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    .text {
      .head {
        font-family: "Medium";
        font-size: 16px;
        line-height: 19px;

        color: #000000;
      }
      .bottom {
        font-family: "Light";
        font-size: 14px;
        line-height: 16px;
        color: #999999;
      }
    }
  }
}
