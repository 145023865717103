@import "/src/style/breakpoints";
@import "/src/style/colors";

.Note {
  .Button {
    svg {
      transform: none !important;
      margin: 0;
      height: 18px;
      width: 18px;
      stroke-width: 1.8px;
    }

    &.active {
      svg {
        fill: white;

        :last-child {
          color: #00df94
        }
      }
    }
  }
}

.NoteDialog {
  .p-dialog-header {
    * {
      font-family: "Bold" !important;
      font-size: 20px !important;
      line-height: 23px;
      color: #000000;
    }
  }
  .p-dialog-header{
    padding-bottom: 5px !important;
  }
  .p-dialog-content{
    padding-bottom: 15px !important;
  }
  textarea {
    width: 100% !important;
    margin: 10px 0;
  }
  .p-inputtext:enabled:hover {
    border-color: $grey;
  }
  .p-inputtext:enabled:focus {
    outline: 0 none;
    outline-offset: 0;
    box-shadow: 0 0 0 0.2rem $light-grey;
    border-color: $grey;
  }
  .btns {
    display: flex;
    justify-content: flex-end;
  }
}
