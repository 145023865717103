.ResultLineBloc {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  display: none;
  .ResultLine {
    position: absolute;
    transform: rotate(36deg);
    width: 150%;
    height: 25px;
    z-index: 0;

    background: rgba(255, 255, 255, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      color: rgb(255, 255, 255);
      font-size: 14px;
      font-family: "Bold";
    }
  }
}

.sold,
.not-sold,
.reserved,
.active_lot {
  .ResultLineBloc {
    display: flex !important;
  }
}
.sold {
  .ResultLine {
    background: rgba(0, 223, 149, 0.6) !important;
  }
}
.not-sold {
  .ResultLine {
    background: rgba(255, 0, 0, 0.6) !important;
  }
}
.reserved {
  .ResultLine {
    background: rgba(255, 187, 1, 0.6) !important;
  }
}
.active_lot {
  .ResultLine {
    background: rgba(1, 221, 255, 0.6) !important;
  }
}
