.InfoAboutAuction {
  .DropDown {
    margin: 40px 0;
    .header {
      font-family: "Medium";
      font-size: 20px;
      line-height: 23px;
      justify-content: flex-start;
      .toggler {
        margin: 0 15px;
      }
    }
    .content {
      .paragraph {
        text-align: justify;
        margin: 15px 0;
        span {
          font-size: 16px;
          line-height: 19px;
          opacity: 1;

          h4 {
            display: inline;
            font-family: "Medium";
            margin-right: 10px;
            line-height: 23px;
            opacity: 1;
          }
        }

        ul {
          margin: 5px;
          li {
            margin: 5px;
            text-indent: 25px;
          }
        }
      }
    }
  }
  table {
    border: 1px solid rgb(140, 140, 140);
    border-collapse: collapse;
    tr {
      td {
        width: 25%;
        vertical-align: top;
        border: 1px solid rgb(195, 192, 192);
        border-collapse: collapse;
        padding: 20px;
      }
    }
  }
}
