.FinalPrice {
  .title {
    display: flex;
    flex-direction: column;
    gap: 2px;
    .heading {
      font-size: 16px;
      font-family: "Semibold";
      font-style: normal;
      line-height: normal;
    }
    .price {
      font-size: 14px;
      font-family: "Semibold";
      font-style: normal;
      line-height: normal;
    }
  }
  .note {
    color: #999;
    font-size: 12px;
    font-family: "Regular";
    font-style: normal;
    line-height: normal;
  }
  .surcharge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 30px;
    align-items: center;
    // border-bottom: 0.5px solid rgba(0, 0, 0, 0.239);
    &:last-child {
      border-bottom: none;
    }
    .title {
      font-size: 14px;
      font-family: "Light";
    }
    .price {
      font-size: 14px;
      font-family: "SemiBold";
    }
  }
}
