.TableDocuments {
  td {
    max-width: 300px;
    overflow-x: auto;
  }

  border-color: black;
  width: 100%;
  table-layout: fixed;
  text-align: center;
  width: 100%;
  th {
    font-weight: 400;
    font-family: "Medium";
    background-color: white;
  }
  tr:nth-child(odd) {
    background-color: #eeeeee40;
  }
  td {
    border-top: 3px solid rgba(255, 0, 0, 0);
    border-bottom: 3px solid rgba(255, 0, 0, 0);
    overflow: hidden;
    word-wrap: break-word;
  }
  a {
    &.inactive {
      opacity: 0.35;
      cursor: unset !important;
      * {
        cursor: unset !important;
      }
    }
  }
}
