.AuctionBloc{
  display: flex;
  flex-direction: column;
  gap: 30px;
  .ShadowContainerActiveLot{
    padding-bottom: 10px;
  }
  .mainContent {
    padding: max(50px, 3vw);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 48px;
  }
}