.Step_1 {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .titles {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .main_title {
      width: fit-content;
      display: flex;
      flex-direction: column;

      font-size: 34px;
      line-height: 40px;
      font-weight: 500;
    }
    .sub_title {
      width: auto;
      font-size: 16px;
      line-height: 19px;
      font-weight: 100;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    .InputNumber {
      max-width: 260px;

      .react-tel-input {
        .country-list {
          top: 30px;
          background-color: #ffffff;
        }
      }
    }
  }
}
