.ModerationDocuments {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-top: 30px;
  position: relative;
  > .card {
    .p-selectbutton {
      display: flex;
      flex-direction: row;
      .p-button {
        width: auto;
        height: 45px;
        white-space: nowrap;
        .p-button-label {
          margin: 5px 2vw;
        }
      }
    }
  }
  .header {
    gap: 2vw;
    .search-input,
    input {
      width: 20vw;
    }
  }

  td {
    max-width: 300px;
    overflow-x: auto;
  }
  > .ShadowContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-shadow: 0px 1px 14px 5px rgba(0, 0, 0, 0.15);

    .ShadowContainer {
      padding: 20px;
      tr {
        background-color: #ffffff00;
      }
    }
  }

  table {
    border-color: black;
    width: 100%;
    table-layout: fixed;
    text-align: center;
    width: 100%;
    th {
      font-weight: 400;
      font-family: "Medium";
      background-color: white;
    }
    tr:nth-child(odd) {
      background-color: #eeeeee67;
    }
    td {
      border-top: 3px solid rgba(255, 0, 0, 0);
      border-bottom: 3px solid rgba(255, 0, 0, 0);
      overflow: hidden;
      word-wrap: break-word;
    }
  }
  .docStatus{
    >span{
      width: 90%;
      height: 90%;
    }
  }
}
.reason_declined_dialog {
  .dialog {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    .reasons {
      padding: 15px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 15px;
      .reason {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 7px;
        border: 2px solid rgb(27, 182, 102);
        width: 90%;
        height: 40px;
        &.active {
          transform: scale(1.01);
          background-color: #00df9572;
        }
      }
    }
  }
}
