.Step_2 {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .titles {
    width: max-content;
    display: flex;
    flex-direction: column;
    gap: 5px;
    .main_title {
      width: fit-content;
      display: flex;
      flex-direction: column;

      font-size: 34px;
      line-height: 40px;
      font-weight: 500;
    }
    .sub_title {
      width: auto;
      font-size: 16px;
      line-height: 19px;
      font-weight: 100;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 40px;
    width: 100%;

    .InputCode {
      display: flex;
      flex-direction: column;
      gap: 5px;
      label {
        font-family: "Medium";
        font-size: 18px;
        line-height: 21px;
        font-family: "Medium";
        text-align: left;
        color: #000000;
        margin-bottom: 10px;
      }

      .codeInput {
        display: flex;
        align-items: center;
        position: relative;
        overflow: hidden;
        height: 29px;
        &::after {
          content: "";
          position: absolute;
          left: 160px;
          background-color: rgb(255, 255, 255);
          height: 29px;
          width: 30px;
          overflow: hidden;
          z-index: 5;
        }
        input {
          position: absolute;
          left: 10px;
          z-index: 3;
          background-color: #00000000;
          letter-spacing: 31px;
          font-size: 1em;
          border: none;
          outline: none;
          &:focus-visible {
            outline: none;
            border: none;
          }
        }
        .numbers {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 10px;

          .number {
            letter-spacing: 5px;
            border: none;
            width: 30px;
            height: 29px;
            background: #f4f4f4;
            border-radius: 6px;
            text-align: center;
          }
        }
      }
      .under_input_text {
        font-size: 12px;
        line-height: 14px;
        color: #999999;
      }
    }

    .navigationBloc {
      display: flex;
      flex-direction: column;
      gap: 17px;
      .navigationBtn {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }
      .InputNumber {
        max-width: 260px;
      }

      .greenwhite {
        padding: 0;
        font-weight: 800;
      }
      .gray {
        font-family: "Medium";
      }
    }
  }
}
