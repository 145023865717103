.HelpPage {
  display: flex;
  flex-direction: column;
  gap: 40px;
  * {
    font-family: "Regular" !important;
  }
  .greetings {
    .hello {
      font-size: 24px;

      margin-bottom: 5px;
    }
    .questions {
      font-size: 22px;
    }
  }
  .selectProblems {
    .title {
      font-size: 22px;
      margin-bottom: 15px;
    }
    .problems {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .problem {
        display: flex;
        flex-direction: row;
        align-items: center;
        font-size: 18px;
        gap: 20px;
        margin-left: 7px;
        height: 40px;
        .Input {
          font-family: "Regular";
          font-size: 18px;
          height: 100%;
          width: 100%;
          position: relative;
          top: 5px;
          * {
            color: black !important;
          }
        }

        .CheckBox {
          border-radius: 4px;
          svg {
            width: 25px;
            height: 25px;
          }
          width: 27px;
          height: 27px;
        }
      }
    }
  }
  .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .text {
      font-size: 22px;
    }
    .title,
    .headerTitle {
      font-size: 18px;
    }
    .Button {
      font-size: 22px;
      width: 100%;
      height: 50px;
    }
  }
}
