.InputNumber {
  width: 100%;

  label {
    font-family: "Medium";
    font-size: 18px;
    line-height: 21px;
    text-align: left;
    color: #000000;
    margin-bottom: 30px;
  }
  .react-tel-input {
    * {
      border-bottom: none !important;
    }

    .form-control {
      position: relative;
      left: 50px;
      width: 80%;
    }
    .flag-dropdown.open {
      background: none !important;
    }
    .flag-dropdown {
      border: none;
      background: none;
      .selected-flag {
        background: none;
      }
      .country-list {
        background-color: #ffffff;
        border-radius: 7px;

        &::-webkit-scrollbar {
          width: 4px !important;
        }

        &::-webkit-scrollbar-track {
          background-color: rgba(0, 0, 0, 0);
        }

        &::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background-color: black;
        }
      }
    }
    margin-top: 10px;
    border-bottom: 1px solid #999999;
    input {
      display: block;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      padding: 8px 2px;
      color: #777;
      outline: none;
      border: none;
    }
  }

  .under_input_text {
    font-size: 12px;
    line-height: 14px;
    color: #999999;
  }
}
