@import "/src/style/breakpoints";
@import "/src/style/colors";

.Tariffs {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .text {
    font-family: "Regular";
    font-size: 14px;
    line-height: 16px;
  }
  .select {
    margin: 10px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 30px;
    flex-wrap: wrap;

    .selectItem {
      &.unActive {
        opacity: 0.5;
      }
      gap: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and (max-width: $extra-large) {
        width: 45%;
      }
      @media screen and (max-width: $large) {
        width: 90%;
      }
      .title {
        font-family: "Medium";
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 3px;
      }
      .limit,
      .active_auctions {
        white-space: nowrap;
        font-family: "Light";
        font-size: 14px;
        line-height: 16px;
      }
    }
  }
  .notes {
    display: flex;
    flex-direction: column;
    gap: 10px;
    font-family: "Light";
    font-size: 14px;
    line-height: 16px;
  }
  .btns {
    display: flex;
    flex-direction: row;
    gap: 15px;
    width: 100%;
    align-items: center;
    justify-content: center;
    .text {
      font-size: 16px;
      font-family: "Medium";
    }
  }
}
