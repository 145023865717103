.Messages {
  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }

  .ShadowContainer {
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    >div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      svg {
        cursor: pointer;
        transform: scale(1.3);
      }
    }
    .date {
      color: rgba(0, 0, 0, 0.602);
    }
    .content{
      a {
        cursor: pointer;
        color: #00df94;
        border-bottom: 1px solid black;
      }
    }
  }
  .buttonDownloadInvoice{
    font-size: 16px;
    display: inline;
    padding: 0 !important;
    border-radius: 0 !important;
  }
}
