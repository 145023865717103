.Loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 90%;
  margin: 0 auto;

  p {
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    word-spacing: 4px;
    text-align: center;
    margin-bottom: 15px;
  }

  img {
    width: 60px;
    height: 60px;
    animation: rotate 1s ease-in-out infinite;
    z-index: 2;
    @media screen and (max-width: 750px) {
      margin-top: 50px;
      margin-bottom: 50px;
    }
  }
  @keyframes rotate {
    from {
      transform: rotate(-90deg);
    }
    to {
      transform: rotate(270deg);
    }
  }
}

@media screen and (max-width: 670px) {
  .Loader {
    p {
      font-size: 16px;
    }
  }
}
