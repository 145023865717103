.noCarsFound {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  align-items: center;
  justify-content: center;
  h1 {
    font-family: "Bold";
    font-size: 34px;
    color: #00df94;
  }
}
