.Events {
  .infinite-scroll-component {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .ShadowContainer {
    padding: 20px;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    align-items: center;
    > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 20px;
      svg {
        cursor: pointer;
        transform: scale(1.3);
      }
    }

    .date {
      color: rgba(0, 0, 0, 0.602);
    }
  }
  .userLink {
    color: rgb(49 153 118);
    text-decoration: underline;
  }
}
