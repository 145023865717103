.GeneralPages {
  min-height: 70vh;
  .container {
    display: flex;
    flex-direction: row;
    gap: 60px;

    .navbar {
      display: flex;
      flex-direction: column;

      width: fit-content;
      li {
        padding:  10px 0 ;
        width: fit-content;
        white-space: nowrap;
        a {
          white-space: nowrap;
          width: 100%;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #000;
        }
        &.active {
          a {
            font-family: 'SemiBold';
            color: #00df94;
          }

          &::after {
            content: "";
            background: #00df94;
          }
        }

        &::after {
          content: "";
          width: 0%;
          height: 1px;
          background: black;
          display: block;
          margin-top: 1px;
          transition: all 0.3s;
        }

        &:hover {
          &::after {
            width: 100%;
          }
        }
      }
    }
    .bloc-info {
      > h1 {
        font-family: "Medium";
        font-size: 34px;
        line-height: 40px;
      }
    }
  }
}
