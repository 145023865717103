@import "/src/style/breakpoints";
@import "/src/style/colors";

.VerificationDocuments {
  display: flex;
  flex-direction: column;
  gap: 35px;
  .content {
    display: flex;
    flex-direction: column;
    gap: 50px;
    > section {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &.documents .data_entry {
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
      .titles {
        display: flex;
        flex-direction: column;
        gap: 5px;
        .main_title {
          width: fit-content;
          display: flex;
          flex-direction: column;

          font-size: 34px;
          line-height: 40px;
          font-weight: 500;
        }
        .sub_title {
          font-size: 16px;
          line-height: 19px;
          font-weight: 100;
        }
      }
      .TypeAccount {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-evenly;
      }

      .data_entry {
        width: 100%;
        height: 50%;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 20px;
        .Input {
          min-width: 150px;
          width: 30%;
          @media screen and (max-width: $extra-large) {
            width: calc(50% - 20px);
          }
          .under_input_title {
            opacity: 0;
            color: red;
          }
        }
      }
    }
    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .Button {
        font-size: 20px;
        width: 230px;
        height: 60px;
      }
    }
  }
}
