.ConvertStatus {
  svg {
    height: 15px;
    width: 15px;
    fill: #00df94;
  }
  div {
    display: flex;
    flex-direction: row;
    gap: 0px;
    align-items: center;
    &.green {
      gap: 8px;
      color: #00df94;
    }
    &.red {
      gap: 8px;
      color: red;
      svg {
        fill: red;
      }
    }
  }
}
