.UploadDocuments {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  #file {
    width: 400px;
    height: 200px;
    opacity: 0 !important;
  }
  .fileUpload {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 400px;
    height: 200px;
    position: absolute;
    cursor: pointer;
    background-color: #00ffaa16;
    border: 4px dashed #00ffaa;
    border-radius: 15px;
    width: 400px;
    height: 200px;
    font-size: 28px;
    font-family: "Regular";
    color: rgba(0, 0, 0, 0.78);
  }
  .dataFile {
    position: absolute;
    width: 400px;
    text-align: center;
    bottom: 25px;
    .btUpLoad {
      margin-top: 7px;
      cursor: pointer;
      font-size: 18px;
      font-family: "Regular";
      color: rgba(0, 0, 0, 0.78);
      text-decoration: underline;
    }
  }
}
