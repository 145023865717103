.Verification {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    section {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      gap: 10px;
      align-items: center;
      .CheckBox {
        width: 50px;
        height: 50px;
        * {
          width: 40px;
          height: 40px;
        }
      }
      div {
        font-family: Medium;
        &.active {
          color: #00df94;
          font-family: "Bold";
        }
      }
    }
  }
}
