.RadioGroup {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  div {
    display: flex;
    flex-direction: row;
    gap: 5px;
  }
}