.Select {
  .p-dropdown {
    width: 100%;
    padding: 10px;
    height: 37px;
    background-color: #f4f4f4 !important;
    border-radius: 5px;
    border: none !important;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    box-shadow: none !important;
    padding-left: 5px !important;
    * {
      font-family: "Medium" !important;
      background-color: #f4f4f4 !important;
      border: none !important;
      color: black !important;
    }
    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
    }
    .p-dropdown-label {
      padding: 8px !important;
      justify-content: center;
    }
    .p-dropdown-trigger {
      width: 1.5rem !important;
    }
  }
}

.p-dropdown-panel {
  z-index: 100 !important;
  .p-dropdown-item {
    font-family: "Medium" !important;
    &:hover {
      background-color: #f3f3f479 !important ;
    }
    &.p-highlight {
      background-color: #f3f3f4 !important ;
      color: black !important;
    }
  }
}
