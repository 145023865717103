.LotMiniGalleria {
  .p-galleria {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 6px;
    .p-galleria-content {
      justify-content: center;
      width: 100%;
      border-radius: 6px;
    }
  }
  .p-galleria-item {
    height: 190px;
    width: auto;
    overflow: hidden;
    img {
      width: auto;
      height: 100%;
      border-radius: 6px;
    }
  }
  .p-highlight {
    button {
      background-color: #00df94 !important;
    }
  }
  .p-galleria-indicators {
    transform: scale(0.75);
    padding: 8px 0 5px 0 !important;
  }
  .p-galleria-item-wrapper,
  .p-galleria-item-container {
    justify-content: center;
    border-radius: 6px;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
}
