@import "/src/style/breakpoints";
@import "/src/style/colors";

.PasswordChange {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .inputs {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
    width: 100%;
    > * {
      width: calc(33% - 30px);
      @media screen and (max-width: $extra-large) {
        width: calc(50% - 30px);
      }
      @media screen and (max-width: $medium) {
        width: 100%
      }
    }

    .under_input_title {
      opacity: 1;
    }
  }
}
