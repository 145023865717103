.LastBids {
  .title {
    font-size: 20px;
    font-family: "Bold";
    margin-bottom: 14px;
  }
  .bits {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .bit {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .icon {
    }
    .price {
      font-size: 16px;
      font-family: "SemiBold";
    }
    .userName {
      font-size: 16px;
      font-family: "Regular";
    }

    &.green {
      .price,
      .userName {
        color: #00df94;
      }
    }
    &.red {
      .price,
      .userName {
        color: red;
      }
      svg {
        fill: red;
        * {
          stop-color: red;
        }
      }
    }
  }
}
