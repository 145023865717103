.ContactDetails {
  display: flex;
  flex-direction: column;
  gap: 25px;

  .Input {
    .headerTitle {
      font-size: 18px;
    }
  }
  input {
    max-width: 250px;
  }
  .title {
    font-size: 34px;
    line-height: 40px;
  }
  .email,
  .numberPhone {
    display: flex;
    flex-direction: column;
    gap: 36px;
    .react-tel-input {
      margin-top: 0;
      max-width: 250px;
    }

    .greenwhite {
      padding: 0;
      font-weight: 800;
    }
    .bts {
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
