@import "/src/style/breakpoints";
@import "/src/style/colors";

.create_new_lot_dialog {
  width: 700px;
  @media screen and (max-width: $extra-large) {
    width: 700px;
    * {
      font-size: 30px !important;
    }
  }
  .p-dialog-footer {
    display: flex;
    justify-content: flex-end;
    .create_bt {
      @media screen and (max-width: $extra-large) {
        * {
          font-size: 16px !important;
        }
        transform: scale(1.5) translateX(-20px);
      }

      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }

  .create_new_lot_dialog_context {
    padding: 10px 20px 10px 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}
@media screen and (max-width: $extra-large) {
  .p-dropdown-panel,
  .p-multiselect-panel {
    * {
      font-size: 30px !important;
    }
    .p-dropdown-item {
      padding: 1.75rem 1.25rem !important;
    }
    .p-dropdown-items-wrapper {
      max-height: 400px !important;
    }
  }
}

.AddLotsUnderlot {
  @media screen and (max-width: $extra-large) {
    .SelectButton {
      transform: scale(2);
    }
  }
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  flex-direction: column;
  @media screen and (max-width: $extra-large) {
    gap: 80px;
  }
  .preHeader {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 85px;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 15px;
    gap: 10px;
    .Button {
      height: 36px !important;
      font-size: 16px;
    }
  }
  .plus {
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    @media screen and (max-width: $extra-large) {
      transform: scale(2);
    }
  }
  .finishEdit {
    font-family: "Semibold";
    @media screen and (max-width: $extra-large) {
      transform: scale(2);
    }
  }

  .AddLotsUnderlot_container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: $extra-large) {
      * {
        font-size: 30px !important;
      }
      .CheckBox {
        transform: scale(1.5);
      }
    }
    .Photos {
      @media screen and (max-width: $extra-large) {
        width: 90%;
      }
    }

    .FilterOptions,
    .Description {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 900px;
      width: 100%;
      @media screen and (max-width: $extra-large) {
        gap: 80px;
      }
    }
    .OtherOptions {
      display: flex;
      flex-direction: column;
      gap: 30px;
      max-width: 1000px;
      width: 100%;
      @media screen and (max-width: $extra-large) {
        gap: 150px !important;
      }
      .CheckBoxItem {
        display: flex;
        flex-direction: row;
        gap: 15px;
        font-family: "Medium";
        @media screen and (max-width: $extra-large) {
          font-size: 30px !important;
        }
      }
      .group {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 30px;
        h1 {
          @media screen and (max-width: $extra-large) {
            font-size: 40px !important;
          }
        }

        > div {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 20px;
          @media screen and (max-width: $extra-large) {
            gap: 50px !important;
          }
          .FeatureElement {
            width: 300px;
            align-items: flex-start;
            .CheckBoxItem {
              display: flex;
              gap: 30px;
              align-items: center;
            }
            > * {
              width: 100% !important;
            }
            @media screen and (max-width: $extra-large) {
              width: 100% !important;
            }
            &:not(.CheckBoxItem) {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
            }
          }
        }
      }
    }
  }
}
