.DescriptionFrame {
  position: relative;
  width: 100%;
  .CheckMark {
    transform: scale(1.1);
    path {
      stroke: black !important;
    }
  }
  .translate {
    font-family: "Bold";
    font-size: 17px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .info {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 64px;
    .DropDown {
      .header {
        padding-right: 10px;
        font-size: 20px;
        font-family: "Bold";
      }
      .content.active {
        /*animation: none;*/
      }
      .content {
        ul {
          li {
            display: flex;
            padding: 10px;
            align-items: center;
            justify-content: space-between;
            border-bottom: 0.5px solid rgba(0, 0, 0, 0.162);
            > div {
              width: 100%;
            }
            /*&:nth-last-child(1) {
              border-bottom: none ;
            }*/
            .img {
              cursor: pointer;
              width: fit-content;
              img {
                border-radius: 5px;
                height: 75px;
              }
            }
          }
        }
      }
    }
  }
}
