@import "/src/style/breakpoints";
@import "/src/style/colors";

.home-page-bottom-section {
  padding-top: 106px;
  padding-bottom: 100px;
  .popular_title {
    font-family: "Medium";
    font-size: 34px;
    line-height: 41px;
    color: #000000;
    @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .popular_short {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    margin-bottom: 19px;
    margin-top: 4px;
  }
  .popular_manufacturers_swiper {
    padding: 5px 0;
    .swiper {
      overflow: unset !important;
    }
  }

  .popular_card {
    background: #ffffff;
    box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 21px 20px;
    position: static;
    height: 79px;
    &:hover {
      path {
        stroke: #00df94;
      }
    }
    .popular_img {
      float: left;
      margin-right: 20px;
      > img {
        height: 36px;
      }
    }
    .popular_name {
      > h5 {
        font-family: "Medium";
        font-size: 14px;
        line-height: 17px;
        color: #000000;
      }
      > h6 {
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #000000a2;
        margin-top: 6px;
        > svg {
          float: right;
        }
      }
    }
  }

  .faq_swiper {
    overflow: unset !important;
    padding: 5px 0;

    .swiper {
      overflow: unset !important;

      .swiper-slide {
        height: unset !important;

        & > * {
          height: 100% !important;
        }
      }
    }
  }

  .expensive_section {
    margin-top: 106px;
    @media screen and (max-width: $medium) {
      margin-top: 64px;
    }
  }

  .expensive_car {
    display: flex;

    @media screen and (max-width: $medium) {
      display: block;
    }
  }

  .expensive_title {
    font-family: "Medium";
    font-size: 34px;
    line-height: 41px;
    color: #000000;
    @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .expensive_short {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    margin-bottom: 24px;
    margin-top: 4px;
  }
  .expensive_car_img {
    width: 100%;
    max-width: 673px;
    height: 371px;
    margin-right: 78px;
    @media screen and (max-width: $medium) {
      height: 235px;
    }
    > img {
      width: 100%;
      height: 371px;
      border-radius: 6px;
      object-fit: cover;
      @media screen and (max-width: $medium) {
        height: 235px;
      }
    }
  }
  .expensive_car_info {
    .expensive_car_title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 41px;
      color: #000000;
      @media screen and (max-width: $medium) {
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
      }
    }
    .expensive_car_tags{
      margin-top: 10px;
    }
  }

  .expensive_car_price {
    margin-top: 45px;
    @media screen and (max-width: $medium) {
      margin-top: 20px;
    }
    .expensive_price {
      float: left;
      min-width: 130px;
      > h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #000000;
      }
      > h4 {
        font-family: "Medium";
        font-size: 20px;
        line-height: 24px;
        color: #000000;
        margin-top: 5px;
      }
    }
    .expensive_current_rate {
      > h5 {
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        // color: #00df94;
      }
      > h4 {
        font-family: "Medium";
        font-size: 20px;
        line-height: 24px;
        // color: #00df94;
        margin-top: 5px;
      }
    }
  }

  .view_car {
    margin-top: 45px;
    @media screen and (max-width: $medium) {
      margin-top: 20px;
    }
    > a {
      font-family: "Medium";
      font-size: 16px;
      line-height: 19px;
      color: #00df94;
      > svg {
        margin-left: 14px;
      }
    }
  }

  .home_faq_section {
    margin-top: 106px;
    @media screen and (max-width: $medium) {
      margin-top: 64px;
    }
  }
  .home_faq_title {
    font-family: "Medium";
    font-size: 34px;
    line-height: 41px;
    color: #000000;
    @media screen and (max-width: $medium) {
      font-family: "Medium";
      font-size: 20px;
      line-height: 24px;
    }
  }
  .home_faq_short {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    margin-bottom: 19px;
    margin-top: 4px;
    @media screen and (max-width: $medium) {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
  .home_faq_card {
    background: #ffffff;
    box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    border-radius: 0px 0px 6px 6px;
    padding: 20px;

    .title {
      font-family: "Medium";
      font-size: 16px;
      line-height: 19px;
      color: #000000;
      min-height: 40px;
      @media screen and (min-width: $extra-small) {
        font-size: 13px;
        line-height: 15px;
      }
    }
    .text {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #000000;
    }
  }
  @media screen and (max-width: $medium) {
    padding-top: 64px;
  }
}
