.CheckBox {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  aspect-ratio: 1/1;
  background-color: #ffffff;
  border: 1px solid gainsboro;
  fill: white;
  svg {
    transform: rotate(180deg);
  }
  &.active {
    background-color: #00df94;
    border: none;
  }
  &.square {
    border-radius: 5px;
  }
}
