@import "/src/style/breakpoints";
@import "/src/style/colors";

.filterContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  .filtersBlock {
    width: 526px;

    .title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 41px;
      letter-spacing: 1px;
      color: #000000;
      @media screen and (max-width: $large) {
        color: #ffffff;
        text-align: center;
        margin-top: 107px;
      }
    }
    .short {
      font-weight: 300;
      font-size: 16px;
      line-height: 18px;
      color: #000000;
      margin-top: 6px;
      @media screen and (max-width: $large) {
        color: #ffffff;
        text-align: center;
      }
    }
    .homePage-loader {
      font-size: 22px;
      font-weight: 400;
    }
    @media screen and (min-width: $large) and (max-width: $extra-extra-large) {
      width: 400px;
      .homePage_more {
        margin-top: 100px !important;
      }
    }

    @media screen and (max-width: $large) {
      min-width: 100%;
      max-width: 100%;
      z-index: 1;
    }
  }
}
