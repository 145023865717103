.Authentication {
  max-width: 500px !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-bottom: 20vh;
  padding: 50px 100px;
  margin-top: 10vh;
  gap: 5vh;
  header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 40px;
      color: #000000;
    }
    span {
      font-weight: 100;
      font-size: 16px;
      line-height: 19px;
      a {
        font-weight: 100;
        font-size: 16px;
        line-height: 19px;
        color: #00df94;
        &:hover {
          color: #00c886;
        }
        &:active {
          color: #00b176;
        }
      }
    }
  }
  .form {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    .headerTitle {
      font-family: "Medium";
    }
  }
  .links {
    display: flex;
    flex-direction: row;
    gap: 20px;
    .Button {
      font-size: 16px;
      line-height: 19px;
      color: black;
      background-color: white;
      border: 1px solid black;
    }
    .forgotPass {
      align-self: center;
      color: #00df94;
    }
  }
  .rights {
    fieldset {
      padding: 10px;
      border-radius: 5px;
    }
    div{
      display: flex;
      flex-direction: row;
      gap: 10px;
    }
  }
}
