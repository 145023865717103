.LotsFilters {
  display: flex;
  flex-direction: column;
  gap: 30px;
  .filter {
    width: 225px;
    .content.active {
      animation: none;
    }
  }
}
