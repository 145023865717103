@import "/src/style/breakpoints";
@import "/src/style/colors";

* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

html,
body {
  margin: 0px !important;
  padding: 0px !important;
  font-family: "Regular";
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-color: rgba(0, 0, 0, 0.827) #ffffff00;
  scrollbar-width: thin;
}

body {
  min-width: $body-min-width;
}

.container {
  width: min(90%, 90rem);
  /* max-width: 1440px; */
  max-width: 1315px;
  margin-inline: auto;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
}

.separator {
  height: 3px;
  background: #d2d2d2;
  border: none;
}

select {
  width: 100%;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5px;
  outline: none;
  background-color: #f3f3f4;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid #777;
}

.MuiPaper-root.MuiMenu-paper.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded,
.p-dropdown-items-wrapper {
  padding: 5px;
  width: auto;
  border-radius: 7px;

  &::-webkit-scrollbar {
    width: 4px !important;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;

    background-color: black;
  }
}

.color-green {
  color: #00df94;
}
.color-red {
  color: #ff0000;
}

body::-webkit-scrollbar {
  width: 5px;
}

/* Track */
body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.2);
}
/* Handle */
body::-webkit-scrollbar-thumb {
  background: black;
}

/* Handle on hover */
body::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.icon-for-downloading {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  align-items: center;

  transform: scale(0.8);
  transition: all 0.1s;
  position: relative;
  left: 5px;
  top: 6px;
  background: url("./assets/icons/icon-download_green.png") center no-repeat;
  &:hover {
    transform: scale(0.9);
  }
}

.icon-for-delete_file {
  cursor: pointer;
  display: inline-block;
  width: 30px;
  height: 30px;
  align-items: center;

  transform: scale(0.8);
  transition: all 0.1s;
  position: relative;
  left: 5px;
  top: 6px;
  background: url("./assets/icons/icon_delete_file_green.png") center no-repeat;
  &:hover {
    transform: scale(0.9);
  }
}
