@import "/src/style/breakpoints";
@import "/src/style/colors";

.Footer {
  margin-top: 100px;
  text-align: center;
  background: #f4f4f4;
  width: 100%;

  .footer-container {
    padding: 64px 0 80px 0;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 70px;

    * {
      text-align: left;
    }

    .footer-left {
      width: 200px;

      .payment_providers {
        display: flex;
        flex-direction: column;
        margin-top: 25px;

        img {
          max-width: 200px;
          width: 100%;
        }
      }

      .footer_logo {
        min-width: 301px;
        text-align: left;

        .footer_logo_text {
          font-family: "ExtraBold";
          height: 44px;
          font-style: normal;
          font-weight: 800;
          font-size: 36px;
          line-height: 44px;
        }
      }

      .footer_address {
        margin-top: 40px;
        font-weight: 300;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        text-align: left;

        .footer_address_line:not(:first-child) {
          margin-top: 14px;
        }
      }

      .footer_social {
        margin-top: 50px;
        font-family: "Medium";
        font-size: 16px;
        line-height: 19px;
        text-align: left;

        a:not(:first-child) {
          margin-left: 30px;
        }
      }
    }

    .footer-right {
      width: 100%;
      height: 100% !important;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .footer_title {
        text-align: left;

        .title {
          font-family: "Medium";
          font-size: 20px;
          line-height: 24px;
          color: #000000;

          @media screen and (max-width: $medium) {
            margin-top: 64px;
          }
        }

        .desc {
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          margin-top: 14px;
          color: #000000;
          max-width: 320px;
        }
      }

      .footer_form {
        margin-top: 67px;
        margin-bottom: 20px;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;

        .Input,
        .InputNumber {
          width: 25%;

          input {
            background-color: rgba(163, 148, 148, 0);
          }

          input,
          .react-tel-input {
            height: 30px;
          }
        }

        .InputNumber {
          min-width: 190px;

          .selected-flag {
            background-color: #f4f4f4;
          }

          label {
            margin: 0;
            font-size: 14px;
          }

          .react-tel-input {
            margin: 0;

            .country-list {
              top: -240px;
              background-color: #f4f4f4;
            }
          }

          .under_input_text {
            display: none;
          }
        }

        .Button {
          padding: 24px 40px;
          font-family: "SemiBold";
          font-size: 16px;
        }

        @media screen and (max-width: $large) {
          flex-direction: column;
          gap: 30px;

          .Input,
          .InputNumber {
            width: 100%;

            input {
              background-color: rgba(163, 148, 148, 0);
            }
          }

          .Button {
            width: 100%;
          }
        }

        @media screen and (max-width: $medium) {
          flex-direction: column;
          gap: 30px;

          .Input,
          .InputNumber {
            width: 100%;

            input {
              background-color: rgba(163, 148, 148, 0);
            }
          }

          .Button {
            width: 100%;
          }
        }
      }

      .footer_links {
        margin-top: 83px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;

        a {
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          text-align: center;

          @media screen and (max-width: $medium) {
            font-size: 13px;
          }

          &:hover {
            text-decoration: underline;
          }
        }

        @media screen and (min-width: $medium) and (max-width: $large) {
          margin: 0 40px;
          margin-top: 20px !important;
          position: absolute;
          left: 0;
          width: calc(100vw - 60px);
          justify-content: space-evenly !important;
        }

        @media screen and (max-width: $medium) {
          flex-wrap: wrap;
          column-gap: 0;
          margin-top: 64px !important;

          a {
            display: block;
            width: 50%;
            text-align: left;
          }
        }

        @media screen and (max-width: $extra-small) {
          gap: 0 !important;

          >a {
            width: 100% !important;
            margin: 8px 0 !important;
            text-align: center !important;
          }
        }
      }
    }

    @media screen and (max-width: $medium) {
      padding: 30px 0;
      flex-direction: column;

      .footer-right,
      .footer-left {
        width: 100%;
      }

      .footer-left {
        position: relative;

        .footer_logo .footer_logo_text,
        .footer_social {
          height: 25px;
        }

        .footer_logo .footer_logo_text {
          font-size: 20px;
          line-height: 25px;
        }

        .footer_social {
          margin: 0;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
        }

        .footer_address {
          display: none;
        }
      }

      .footer-right {
        .footer_form {
          flex-direction: column;

          .footer_form_button,
          .footer_form_input {
            margin-top: 30px;
          }
        }
      }
    }
  }
}