@import "/src/style/breakpoints";
@import "/src/style/colors";

.AuctionsList {
  display: flex;
  flex-direction: column;
  gap: 25px;
  .ShadowContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    h4 {
      font-family: "Regular";
      font-weight: 400;
      font-size: 18px;
    }

    .top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .name {
        font-family: "Semibold";
        font-size: 22px;
      }
      .logo {
        img {
          max-width: 100px;
          max-height: 40px;
          border-radius: 5px;
        }
      }
    }

    .bottom {
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      white-space: nowrap;
      gap: 20px;
      @media screen and (max-width: $extra-extra-large) {
        flex-wrap: wrap;
        > div {
          width: 40%;
        }
      }
      @media screen and (max-width: $large) {
        > div {
          width: 100%;
        }
      }
      .date {
        display: flex;
        flex-direction: row;
        gap: 8px;
        align-items: center;
      }
      .timer {
        height: 17px;
        width: 17px;
      }
      .cars {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        span {
          color: #00df94;
        }
      }
    }
  }
}
