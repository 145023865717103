.ChoosingPaymentMethod,
.InfoAboutCheckout {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  justify-content: center;

  .tariffInfo {
    padding: 20px !important;
    width: 600px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    .title {
      font-size: 24px;
      font-family: Light;
      font-weight: bold;
    }

    .info {
      margin-left: 20px;
    }
  }

  .paymentMethods {
    display: flex;
    flex-direction: row;
    gap: 100px;

    >div {
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;

      >.ShadowContainer {
        padding: 20px !important;
        width: 200px;
        height: 160px;
        background-color: rgba(216, 216, 216, 0.127);
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: center;
        justify-content: center;
        box-shadow: 0px 1px 20px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
      }

      .CheckBox {
        box-shadow: 0px 1px 14px 0px rgba(0, 0, 0, 0.15);
      }
    }

    .pp_paynow {
      display: flex;
      flex-direction: column;
      img {
        max-width: 100px;
        width: 100%;
      }
    }
  }

  .note {
    width: 500px;
    font-family: Light;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .btNext {
    .Button {
      width: 500px;
      height: 65px;
      font-size: 24px;
      font-family: Bold;
    }
  }

  .Button {
    &.inactive {
      opacity: 0.5;
      pointer-events: none;

      &:hover {
        background: #00df94;
      }
    }
  }

  .WireTransfer {
    height: 62px;
    width: 100px;
  }
}