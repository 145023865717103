.LotsPage {
  display: flex;
  flex-direction: row;
  gap: 3vw;
  .filters_header {
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .totalLots {
      display: flex;
      align-items: center;
      font-family: "Bold";
      font-size: 20px;
    }
    div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .totalFindLots {
        display: flex;
        align-items: center;
        padding: 8px 14px;
        font-family: "Medium";
        height: 33px;
        border: 1px solid #000000;
        border-radius: 4px;
        font-size: 16px;
      }
      .findBtn {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 8px 14px;
        width: 43px;
        height: 32px;
        border: 1px solid #00df94;
        border-radius: 6px;
      }
    }
  }
  .filters_panel {
    display: flex;
    flex-direction: column;
  }
  .lots_main {
    display: flex;
    flex-direction: column;
    width: 100%;

    .auction_title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .auction_name {
        font-family: "Bold";
        font-size: 20px;
      }
      .info {
        display: flex;
        flex-direction: row;
        gap: 20px;
      }
    }
    .Loader {
      margin-top: 30px;
    }
    .noCarsFound {
      margin-top: 50px;
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 15px;
      align-items: center;
      justify-content: center;
      h1 {
        font-family: "Bold";
        font-size: 34px;
        color: #00df94;
      }
      h2 {
        font-weight: 400;
        font-size: 16px;
      }
    }
  }
  .MuiInput-root {
    padding: 10px;
    height: 37px;
    background-color: #f4f4f4 !important;
    border-radius: 4px;
    border: none !important;
    * {
      background-color: #f4f4f4 !important;
      border: none !important;
    }
    &::before {
      border: none !important;
    }
    &::after {
      border: none !important;
    }
  }
}
