.PasswordRecoveryConfirm {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8vh;
  text-align: center;
  header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 40px;
      color: #00df94;
    }
    span {
      text-align: center;
      font-weight: 100;
      font-size: 16px;
      line-height: 19px;
      display: block;
      padding: 0 10%;
    }
  }
  .form {
    width: 300px;
    display: flex;
    flex-direction: column;
    gap: 4vh;
    .headerTitle {
      font-weight: bold;
    }
  }
  .links {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .back {
      svg {
        margin-right: 10px;
        transform: rotate(180deg);
      }

      color: #00df94;
    }
  }
}
