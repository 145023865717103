.InputCode {
  display: flex;
  flex-direction: column;
  gap: 5px;
  label {
    font-family: "Medium";
    font-size: 18px;
    line-height: 21px;
    font-weight: 700;
    text-align: left;
    color: #000000;
    margin-bottom: 10px;
  }

  .codeInput {
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    height: 29px;
    &::after {
      content: "";
      position: absolute;
      left: 160px;
      background-color: rgb(255, 255, 255);
      height: 29px;
      width: 30px;
      overflow: hidden;
      z-index: 5;
    }
    input {
      position: absolute;
      left: 10px;
      z-index: 3;
      background-color: #00000000;
      letter-spacing: 31px;
      font-size: 1em;
      border: none;
      outline: none;
      &:focus-visible {
        outline: none;
        border: none;
      }
    }
    .numbers {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .number {
        letter-spacing: 5px;
        border: none;
        width: 30px;
        height: 29px;
        background: #f4f4f4;
        border-radius: 6px;
        text-align: center;
      }
    }
  }
  .under_input_text {
    font-family: "Regular";
    font-size: 12px;
    line-height: 14px;
    color: #999999;
  }
}
