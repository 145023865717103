@import "/src/style/breakpoints";
@import "/src/style/colors";

$navar-height: 100px;

.Navbar {
  .navbar-empty {
    height: #{$navar-height + 10px};
  }
  .navbar {
    min-width: $body-min-width;
    background: transparent;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 500;
    left: 0;
    top: 0;
    right: 0;
    height: $navar-height;
    text-align: center;
    transition:
      background-color 0.2s,
      box-shadow 0.2s;
    a {
      color: #fff;
    }
    &.home_page {
      a::after {
        content: "";
        background: rgb(255, 255, 255);
      }
      svg {
        fill: #ffffff;
      }
    }
    &.not_home_page {
      a {
        color: #000;
      }
      svg {
        fill: #000;
      }
      a::after {
        content: "";
        background: rgb(0, 0, 0);
      }
      .links {
        gap: 10px;
      }
      @media screen and (max-width: $large) {
        a {
          color: white;
        }
        svg {
          fill: white;
        }
        a::after {
          content: "";
          background: white;
        }
        .title_underlot {
          color: black !important;
        }
      }
    }

    &.scroll_back {
      background: white;
      box-shadow: 0 4px 8px 0 rgba(8, 8, 8, 0.2);
      svg {
        fill: black;
      }
      a {
        color: black;
        &::after {
          content: "";
          background: black;
        }
      }

      @media screen and (max-width: $large) {
        &.home_page.scroll_back {
          background: black;
        }
        &.not_home_page.scroll_back {
          background: white;
          box-shadow: 0 4px 8px 0 rgba(8, 8, 8, 0.2);
        }
      }
    }

    .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .right {
        .title_underlot {
          font-size: 36px;
          font-family: "Extrabold";
          text-align: left;
          color: #000;
          ::after {
            content: "";
            width: 0;
            height: 0;
          }
          @media screen and (max-width: $large) {
            color: #ffffff;
            font-size: 20px;
            line-height: 25px;
          }
        }
      }
      .left {
        width: 50%;
        transition: all 0.1s linear;
        display: flex;
        flex-direction: row;
        justify-content: center;

        @media screen and (max-width: $extra-extra-large) {
          left: 45%;
          width: 55%;
        }

        a {
          white-space: nowrap;
          font-family: "Regular";
          font-size: 16px;
          line-height: 19px;
          &::after {
            content: "";
            width: 0%;
            height: 0.5px;
            display: block;
            margin-top: 1px;
            transition: all 0.2s;
          }
          &:hover {
            &::after {
              width: 100%;
            }
          }
        }

        .mobileLinks {
          display: flex;
          gap: 10px;
          &.closed {
            width: 100%;

            gap: 15px;
            flex-direction: row;
            justify-content: flex-end;
          }

          .links {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;

            .active {
              color: #00df94 !important;
            }
          }

          .profile {
            display: flex;
            a {
              font-weight: 500;
            }
            @media screen and (max-width: $medium) {
              display: block;
            }
            .profileAuthenticated {
              display: flex;
              flex-direction: row;
              align-items: center;
              gap: 7px;
            }
          }
        }

        .user-icon {
          display: none;
        }

        .burger {
          cursor: pointer;
          display: none;

          &.opened {
            div span {
              opacity: 0;

              &:first-child {
                opacity: 1;
                transform: rotate(45deg);
                top: 3px;
                position: relative;
              }

              &:last-child {
                opacity: 1;
                transform: rotate(-45deg);
                position: relative;
                bottom: 8px;
                width: 100%;
                margin-left: 0;
              }
            }
            span.last {
              width: 100% !important;
            }
          }
          div {
            width: 14px;
            height: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;

            span {
              width: 100%;
              height: 2px;
              background: #ffffff;
              transition: all 0.1s;
              border-radius: 3px;
              &:last-child {
                width: 50%;
                margin-left: auto;
              }
            }
          }

          span.black_menu {
            width: 100%;
            height: 2px;
            background: #000;
            transition: all 0.1s;
            border-radius: 3px;
            &:last-child {
              width: 50%;
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  .activeLink {
    color: #00df94 !important;
  }

  @media screen and (max-width: $large) {
    .title_underlot {
      font-weight: 800;
      font-size: 20px;
      line-height: 25px;
      color: #ffffff;
    }

    .left {
      justify-content: flex-end !important;
      align-items: center;

      .mobileLinks {
        padding: 20px;
        background: rgba(0, 0, 0, 0.95);
        flex-direction: column !important;
        width: 100%;
        position: absolute;
        top: 100px;
        left: 0;
        position: fixed;
        left: 0;
        width: 100vw;
        &.closed {
          display: none !important;
        }
        .links {
          flex-direction: column !important;
          gap: 20px;
          a {
            margin: 10px 0px;
            &::after {
              height: 0px !important;
              width: 0% !important;
            }
            &:hover {
              &::after {
                height: 0px !important;
                width: 0% !important;
              }
            }
          }
        }
        .profile {
          justify-content: center;
          padding: 10px 0px;
          margin-bottom: 15px;
        }
      }

      .user-icon {
        margin-right: 20px;
        width: 20px;
        display: flex !important;
        justify-content: center;
        align-items: center;

        > img {
          width: 13px;
          height: 14px;
        }
      }
      .burger.opened {
        div {
          position: relative;
          top: 2px;
        }
      }
      .burger {
        display: inline-block !important;

        padding: 1em;
        margin: -1em;
      }
    }
  }
}
