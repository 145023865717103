.PaymentResult {
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    height: 500px;
    width: auto;
    display: flex;
    align-items: center;
    > * {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      .icon {
        transform: scale(1.5);
      }
      .title {
        font-size: 36px;
        font-family: Bold;
        color: #00df94;
      }
    }

    .cancelled {
      .icon {
        svg {
          ellipse {
            fill: #ffbb01;
          }
        }
      }
      .title {
        color: #ffbb01;
      }
      .Button {
        background: #ffbb01;
      }
    }
    .failed {
      .icon {
        svg {
          ellipse {
            fill: red;
          }
        }
      }
      .title {
        color: red;
      }
    }
  }
}
