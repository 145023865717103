.Header {
  display: flex;
  flex-direction: column;
  gap: 5px;
  .name_bloc {
    .rightBloc {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 14px;
    }
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 14px;
    .name {
      // overflow: hidden;
      // display: inline-block;
      // width: 100%;
      // white-space: nowrap;
      // text-overflow: ellipsis;
      font-size: 34px;
      font-family: "Bold";
    }
    .controlPanel {
      display: flex;
      flex-direction: row;
      gap: 6px;
    }
  }

  .info {
    display: flex;
    align-items: center;
    gap: 6px;
    margin: 8px 0 24px 0;
    flex-wrap: wrap;
  }
  .bloc {
    display: flex;
    padding: 8px 14px;
    align-items: center;
    border-radius: 4px;
    background: #f4f4f4;
    font-size: 14px;
    white-space: nowrap;
  }
  .value {
    padding-left: 4px;
    font-family: "Semibold";
  }
}
