$light-green: #e5fcf4;
$green: #00df94;
$dark-green: #00cc87;

$white: white;
$light-grey: #f4f4f4;
$grey: #cccccc;
$dark-grey: #999999;
$black: black;

$light-red: #ffe6e6;
$red: #ff0000;
