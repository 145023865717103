.PurchaseProgress{
  width: 100%;
  background: #f4f4f486;
  border-radius: 6px;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
  .info{
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  .ProgressBar{
    *{
      color: black;
    }
  }
  .pay {
    .Button {
      position: relative;
      height: 43px;
      font-size: 18px;
      font-family: "Medium";
      width: 330px;
      float: right;
    }
  }
}
