@import "../../style/breakpoints.scss";

.buttonUp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #00df94;
  border-radius: 25px;
  position: fixed;
  bottom: 65px;
  width: 50px;
  height: 50px;
  z-index: 80;
  right: -80px;
  cursor: pointer;
  transition: all 0.2s;
  @media screen and (max-width: $large) {
    display: none;
  }
  &:hover {
    transform: translateY(-10px);
  }

  svg {
    font-size: 20px;
  }
}

.active-btnTop {
  right: 25px;
}
