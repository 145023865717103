.DropDown {
  display: flex;
  flex-direction: column;
  gap: 10px;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-family: "Medium";
    font-size: 18px;
    line-height: 22px;

    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .toggler {
      height: 20px;
      transition: all 0.1s;
      transform: rotate(180deg);
    }
    &.active {
      .toggler {
        transform: rotate(0deg);
      }
    }
  }
  .content {
    display: none;
    flex-direction: column;
    gap: 5px;
    /* animation-name: hide;
    animation-duration: 0.2s;
    animation-timing-function: linear;*/

    &.active {
      display: flex;
      animation-name: showDropDown;
      animation-duration: 0.15s;
      animation-timing-function: ease-out;
    }
  }
  @keyframes showDropDown {
    from {
      transform: scale(0.8) translate(0, -30%);
      opacity: 1;
    }
    90% {
      transform: scale(1.01);
    }

    to {
      transform: scale(1) translate(0, 0);
      opacity: 1;
    }
  }

  /* TODO: you before  wanted create effect disappearance
  @keyframes hide {
    from {
      transform: translateX(0%);
    }

    to {
      transform: translateX(100%);
    }
  }*/
}
