.InfoCompany {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  h3 {
    font-family: "Semibold";
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
  }
  div {
    h4 {
      font-family: "Semibold";
      font-weight: 400;
      font-size: 16px;
      line-height: 23px;
      color: #000000;
    }
  }
}
