.GeneratePage {
  .DropDown {
    margin: 40px 0;
    .header {
      font-family: "Medium";
      font-size: 20px;
      line-height: 23px;
      justify-content: flex-start;
      color: #000000;
      gap: 5px;
      .toggler {
        margin: 0 15px;
      }
    }
    .content {
      .paragraph {
        text-align: justify;
        margin: 15px 0;
        span {
          font-size: 16px;
          line-height: 19px;
          opacity: 1;

          h4 {
            display: inline;
            margin-right: 10px;
            font-family: "Medium";

            line-height: 23px;
            opacity: 1;
          }
        }

        ul {
          margin: 5px;
          li {
            margin: 5px;
            text-indent: 25px;
          }
        }
      }
    }
  }
}
.bold {
  font-family: "Semibold";
}
.link_underlot {
  color: blue;
  border-bottom: 1px solid blue;
}
.support_underlot {
  color: blue;
  border-bottom: 1px solid blue;
}

.remark {
  text-decoration: underline;
}
