@import "/src/style/breakpoints";
@import "/src/style/colors";

.Lot {
  .ShadowContainer {
    display: flex;
    flex-direction: row;
    padding: 25px;
    padding-bottom: 0;
    gap: 20px;
    flex: 1 2 100px;
    min-height: 220px;
    &:hover {
      animation: hoveLot 0.5s linear forwards;
    }
    @keyframes hoveLot {
      0% {
        background: #ffffff00;
      }
      100% {
        background: #f3f3f456;
      }
    }

    .description {
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 35px;
      width: 100%;
      .main {
        display: flex;
        flex-direction: column;
        gap: 15px;
        .header {
          display: flex;
          flex-direction: row;
          gap: 15px;
          align-items: center;
          .name {
            font-family: "Bold";
            font-size: 20px;
            line-height: 23px;

            color: #000000;
          }
        }

      }
    }
    .prices {
      &.main {
        @media screen and (max-width: $extra-large) {
          display: none !important;
        }
      }
      &.second {
        @media screen and (min-width: $extra-large) {
          display: none !important;
        }
        padding: 0;
        padding-right: 10px;
        width: 100%;
        max-width: none;
        display: flex;
        flex-direction: column;
        gap: 10px;
        align-items: flex-start;
        justify-content: flex-end;
        > div {
          width: 100%;
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          gap: 10px;
        }
        > div:nth-child(1) {
          align-items: flex-end;
        }
      }
      padding-right: 10px;
      width: 25%;
      max-width: 25%;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      padding-bottom: 35px;
      flex: 25%;
      .index {
        font-size: 18px;
        font-family: "SemiBold";
      }
      .prices_container {
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
      .number {
        font-size: 1.1em;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 5px;
        white-space: nowrap;
        .vat {
          display: flex;
          align-items: center;
          img {
            width: 25px;
          }
        }
      }
      span {
        font-family: "Regular";
        margin: 0 5px 0 1px;
      }
      .price {
        font-size: 18px;
        font-family: "Medium";
      }
      .highestBid {
        font-size: 16px;
        font-family: "SemiBold";
      }
    }
  }
}
