@import "../../style/colors.scss";

.MultiSelect {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .headerTitle {
    font-family: "Medium";
    font-size: 16px;
    color: #000000;
  }
  .p-multiselect {
    width: 100% !important;
    min-height: 30px;
    height: 100% !important;
    border: 0;
    border-radius: 0;
    border-bottom: solid 1px #999999;
  }
  .p-multiselect-chip .p-multiselect-token {
    padding: 2.5px 8px !important;
  }
  .p-multiselect-label {
    padding-top: 1px !important;
    padding-bottom: 1px !important;
  }
  .p-multiselect:not(.p-disabled).p-focus {
    box-shadow: none;
    border: 0;
    border-bottom: solid 1px #999999;
  }
  .p-multiselect:not(.p-disabled):hover {
    border-color: initial;
  }
}
.p-multiselect-panel {
  .p-inputtext:enabled:focus {
    box-shadow: none !important;
    border-color: #495057 !important;
  }
  .p-highlight {
    color: #495057 !important;
    background: $light-green !important;
  }
  .p-checkbox .p-checkbox-box.p-highlight {
    background: $green !important;
    border-color: $dark-green !important;
  }
  .p-multiselect-items .p-multiselect-item:focus {
    box-shadow: none !important;
  }
}