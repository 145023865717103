@import "/src/style/breakpoints";
@import "/src/style/colors";

.LotPage {
  .top_section {
    margin-top: 80px;
  }
  iframe {
    overflow: hidden;
  }
  .vat {
    display: flex;
    align-items: center;
    img {
      width: 25px;
    }
  }
  .main {
    max-width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 600px auto;
    row-gap: 1vw;
    column-gap: 3vw;

    .LotGalleria {
      max-width: 640px;
      grid-row: 1/ 2;
      grid-column: 1 / 2;
    }
    .LastBids {
      grid-row: 2/ 3;
      grid-column: 1 / 2;
      height: min-content;
    }
    .AuctionBloc {
      grid-row: 1/ 3;
      grid-column: 2 / 3;
    }
    @media screen and (max-width: calc($extra-large + 50px)) {
      row-gap: 3vw;
      grid-template-rows: none;
      .LotGalleria {
        max-width: 1000px;
        grid-row: 1/ 2;
        grid-column: 1 / 3;
      }
      .LastBids {
        grid-row: 3/ 4;
        grid-column: 1 / 3;
      }
      .AuctionBloc {
        grid-row: 2/ 3;
        grid-column: 1 / 3;
      }
    }
  }
}
