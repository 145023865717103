@import "/src/style/breakpoints";
@import "/src/style/colors";

.SettingsPanel {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  * {
    font-family: "Medium" !important;
  }
  > div {
    display: flex;
    flex-direction: row;
    gap: 20px;
    @media screen and (max-width: $extra-large) {
      gap: 10px;
    }
  }
}
.MuiMenu-list {
  * {
    font-family: "Medium" !important;
  }
}
