@import "/src/style/breakpoints";
@import "/src/style/colors";

.VerificationContract {
  * {
    font-family: "Regular" !important;
  }

  display: flex;
  flex-direction: column;
  gap: 15px;
  .text {
    font-family: "Regular";
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .btns {
    display: flex;
    flex-direction: row;
    margin-right: 20px;
    align-items: center;
    justify-content: center;
    * {
      font-size: 17px !important;
    }
  }
  .checkBox {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .document {
    margin-left: -12px;

    text-decoration: underline;
    font-size: 15px;
  }
  .white {
    background-color: rgba(157, 157, 157, 0.103);
  }
}
.p-dialog-header {
  font-family: "Regular" !important;
  * {
    font-size: 25px !important;
  }
}
