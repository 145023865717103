.Message {
  display: none;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  min-height: 72px;
  border-radius: 6px;

  &.info {
    background: #f4f4f4;
    display: flex;
  }
  &.success {
    background: #e5fcf4;
    display: flex;
  }
  &.error {
    background: #ffe6e6;
    display: flex;
  }
}
