@import "/src/style/breakpoints";
@import "/src/style/colors";

.TopViews {
  margin-top: 160px;
  @media screen and (max-width: $medium) {
    margin-top: 64px;
  }

  .top_title {
    font-family: "Medium";
    font-size: 34px;
    line-height: 41px;
    color: #000000;
    @media screen and (max-width: $medium) {
      font-size: 20px;
      line-height: 24px;
    }
  }
  .top_short {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #999999;
    margin-bottom: 24px;
    margin-top: 4px;
    @media screen and (max-width: $medium) {
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
    }
  }
  $gap: 20px;
  .top_card_area {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: $gap;

    .top_card {
      min-height: 285px;
      background: #ffffff;
      box-shadow: 0px 1px 7px -1px rgba(0, 0, 0, 0.15);
      border-radius: 0px 0px 6px 6px;
      display: flex;
      flex-direction: column;
      flex-shrink: 0;
      width: calc(25% - $gap);

      @media screen and (max-width: $extra-large) {
        width: calc(50% - $gap);
      }

      @media screen and (max-width: $medium) {
        width: 100%;
      }

      .top_img {
        > img {
          width: 100%;
          height: 192px;
          object-fit: cover;
          border-radius: 6px 6px 0px 0px;
        }
      }

      .top_car_block_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        padding: 24px;
        gap: 10px;

        .top_car_name {
          font-family: "Medium";
          font-size: 20px;
          line-height: 24px;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          @media screen and (max-width: $extra-large) {
            font-size: 16px;
          }
        }

        .top_price_area {
          margin-top: 14px;
          flex: 1;
          display: flex;
          align-items: flex-end;
          justify-content: space-between;
          flex-direction: row;
          gap: 10px;
          @media screen and (max-width: $extra-extra-large) {
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
          }
          @media screen and (max-width: $extra-large) {
            align-items: flex-end;
            justify-content: space-between;
            flex-direction: row;
          }

          .top_price_right,
          .top_price_left {
            white-space: nowrap;
            display: flex;
            flex-direction: column;
            h4,
            h5 {
              font-size: 16px;
              line-height: 19px;
            }
          }
          .top_price_right {
            font-family: "Medium";
          }
          .top_price_left {
            font-size: 14px;
            line-height: 16px;
          }
        }
      }
    }
  }
}
