@import "../../style/breakpoints.scss";

.Input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .headerTitle {
    font-family: "Medium";
    font-size: 16px;
    color: #000000;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    opacity: 0;
    pointer-events: none;
  }
  .icon {
    position: absolute;
    width: 16px;
    margin-top: 8px;
    margin-left: 5px;
  }
  input {
    font-size: 14px;
    line-height: 16px;
    padding: 7px 0;
    border-radius: 0;

    width: 100%;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #777;
    outline: none;
    border: none;
    border-bottom: solid 1px #999999;

    @media screen and (max-width: $large) {
      background: none;
      width: 100%;
    }
    &[type="search"] {
      -webkit-appearance: none;
    }
    &:-webkit-autofill {
      -webkit-background-clip: text;
    }
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    -moz-appearance: textfield;
    &:hover,
    &:focus {
      -moz-appearance: number-input;
    }
  }
  .inputBox {
    width: 100%;
    position: relative;
  }
  .btns {
    position: absolute;
    right: 0;
    top: 8px;
    display: flex;
    flex-direction: row;
    gap: 15px;

    .eye {
      cursor: pointer;
    }
    .clear {
      cursor: pointer;
    }
  }

  .under_input_title {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    margin-top: 8px;
    color: #999999;
    @media screen and (max-width: $large) {
      color: #ffffff;
    }
  }
}
