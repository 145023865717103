@import "/src/style/breakpoints";
@import "/src/style/colors";

.LotGalleria {
  width: 100%;
  .ResultLine {
    height: 80px !important;
    span {
      font-size: 32px !important;
    }
  }
}
