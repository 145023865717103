.Step_4 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 15px;
    .title {
      font-family: "Medium";
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      color: #00df94;
    }
    .text {
      font-size: 16px;
      line-height: 19px;
      text-align: center;
      color: #000000b7;

    }
    .Button {
      font-family: "Medium";
      font-size: 14px;
      line-height: 16px;
    }
  }
}
