.MakeBit {
  width: 100%;
  .noAuthenticated {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 6px;
    h4 {
      font-size: 18px;
      font-family: "SemiBold";
    }
    .Button {
      border-radius: 6px;
      margin-top: 6px;
      padding: 14px 24px;
      height: auto !important;
      font-size: 16px;
      font-family: "SemiBold";
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-family: "Bold";
    margin: 10px;
  }
  .account_restrictions {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;

    h5 {
      text-align: center;
      font-size: 14px;
      font-family: "Thin";
    }
  }
  .bidSteps {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 15px;

    .bidStep {
      &.active {
        color: white;
        background: #00df94;
        border: 1px solid #00df94;
      }
      display: flex;
      padding: 16px 24px;
      border-radius: 10px;
      border: 0.5px solid rgba(0, 0, 0, 0.055);
      font-size: 16px;
      font-family: "SemiBold";
      cursor: pointer;
    }
  }
  .Input {
    display: flex;
    flex-direction: row;
    gap: 7px;
    align-items: center;
    justify-content: center;
    * {
      font-size: 19px;
    }
    .headerTitle {
      white-space: nowrap;
    }
    .inputBox {
      width: 60%;
      input {
        text-align: center;
      }
    }
    .under_input_title {
      height: 14px;
      text-align: center;
      color: red;
      font-size: 14px;
    }
  }
  .bottom_text {
    text-align: center;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-family: "Light";
    margin: 10px;
  }
  .bid {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    > * {
      width: 100%;
    }
    .enter {
      display: flex;
      flex-direction: column;
      gap: 3px;
    }
    .Button {
      font-size: 16px;
      border-radius: 5px;
      font-family: "SemiBold";
      height: 50px;
      padding: 16px 24px;
    }
  }
}
